import React, { Component } from 'react';
import DottedLines from './DottedLines';
import PageHeader from './PageHeader';

export default class Goals extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const goals = [
      'My goal is:',
      'Specific actions needed to deliver my goal:',
      'I will measure my success by:',
      'The support I need:',
      'When will I review my progress?',
      'My deadline is:',
    ];

    return (
      <div key={this.props.num} className='page-break bg-white user-report__content'>
        {/* receives the goal number as a prop and uses it to print the respective goal when called */}
        <PageHeader h2Class='mt4' h2Text='Part 3: ' strong='Step 2' h3Text={`Goal ${this.props.num}`} line={true} />
        {goals.map((g, i) => {
          return (
            <div key={this.props.num + i}>
              <p>{g}</p>
              <DottedLines numberOfLines={3} />
            </div>
          );
        })}
      </div>
    );
  }
}
