import React from 'react';

const polarToX = (angle, distance) => Math.cos(angle - Math.PI / 2) * distance;

const polarToY = (angle, distance) => Math.sin(angle - Math.PI / 2) * distance;

const points = (points) => {
  return points.map((point) => point[0].toFixed(4) + ',' + point[1].toFixed(4)).join(' ');
};

const axis = (options) => (col, i) => (
  <polyline
    key={`poly-axis-${i}`}
    points={points([
      [0, 0],
      [polarToX(col.angle, options.chartSize / 2), polarToY(col.angle, options.chartSize / 2)],
    ])}
    stroke='#555'
    strokeWidth='.2'
    {...options.axisProps(col)}
  />
);

const dot = (columns, options) => (chartData, i) => {
  const data = chartData.data;
  const meta = chartData.meta || {};
  const extraProps = options.dotProps(meta);
  let mouseEnter = () => {};
  let mouseLeave = () => {};
  if (extraProps.mouseEnter) {
    mouseEnter = extraProps.mouseEnter;
  }
  if (extraProps.mouseLeave) {
    mouseLeave = extraProps.mouseLeave;
  }
  return columns.map((col) => {
    const val = data[col.key];
    // if ('number' !== typeof val) {
    //   throw new Error(`Data set ${i} is invalid.`);
    // }
    // 'undefined' == typeof val
    return !isNaN(val) && (
      <circle
        key={`dot-${col.key}-${val}`}
        cx={polarToX(col.angle, (val * options.chartSize) / 2)}
        cy={polarToY(col.angle, (val * options.chartSize) / 2)}
        fill={meta.color}
        stroke='none'
        r='5'
        className={[extraProps.className, meta.class].join(' ')}
        onMouseEnter={() => mouseEnter({ key: col.key, value: val, idx: i })}
        onMouseLeave={() => mouseLeave({})}
      />
    );
  });
};

const shape = (columns, options) => (chartData, i) => {
  const data = chartData.data;
  const meta = chartData.meta || {};
  const extraProps = options.shapeProps(meta);
  return (
    <path
      key={`shape-${i}`}
      d={options.smoothing(
        columns.map((col) => {
          const val = data[col.key];

          // if ('number' !== typeof val) {
          //   throw new Error(`Data set ${i} is invalid.`);
          // }

          return [polarToX(col.angle, (val * options.chartSize) / 2), polarToY(col.angle, (val * options.chartSize) / 2)];
        })
      )}
      {...extraProps}
      stroke={meta.color}
      fill='none'
      className={[extraProps.className, meta.class].join(' ')}
    />
  );
};

const scale = (options, value) => (
  <circle key={`circle-${value}`} cx={0} cy={0} r={(value * options.chartSize) / 2} fill='none' stroke='#999' strokeWidth='.2' {...options.scaleProps(value)} />
);
const scaleNumberBG = (options, value) => (
  <circle
    key={`circle-text-bg-${value}`}
    cx={polarToX(0.7853981634, (value * options.chartSize) / 2)}
    cy={polarToY(0.7853981634, (value * options.chartSize) / 2)}
    r={8}
    fill='#999'
    stroke='none'
  />
);
const scaleNumber = (options, value, text) => (
  <text
    key={`circle-text-${value}`}
    x={polarToX(0.7853981634, (value * options.chartSize) / 2)}
    y={polarToY(0.7853981634, (value * options.chartSize) / 2)}
    dx={-2}
    dy={2.5}
    fontWeight='bold'
    fontSize='8px'
    fill='#FFF'
  >
    {text}
  </text>
);

const caption = (options) => (col, colIndex, arr) => {
  const label = wrapLabel(col.caption, 90);
  let xPos = polarToX(col.angle, (options.size / 2) * 0.95).toFixed(4);
  if (xPos == 285.0) {
    xPos = 265.0;
  }
  if (xPos == -285.0) {
    xPos = -265.0;
  }
  // let rotate = 0
  // let transformX = 0
  // let transformY = 0
  // if(xPos > 0) {
  //   rotate = 90
  //   transformX = -50
  //   transformY = -50
  // }

  // transform={`translate(${transformX},${transformY}) rotate(${rotate})`}
  // textAnchor="middle"

  let xAttribute = xPos;
  let yAttribute = polarToY(col.angle, (options.size / 2) * 0.85).toFixed(4);

  const quarterLimit = arr.length / 4;
  const threeQuarterLimit = quarterLimit * 3;

  // calculates an increase to starting position of captions when length would cause an overflow with chart lines
  if (label.length > 2) {
    if (colIndex === 0) {
        const yIncrease = 1 + (0.05 * (label.length - 2));
        yAttribute = yAttribute * yIncrease;
    } else if (colIndex < quarterLimit || colIndex > threeQuarterLimit) {
      const increase = 1 + (0.1 * (label.length - 2));
      yAttribute = yAttribute * increase;
      xAttribute = xAttribute * increase;
    }
  }

  return (
    <text
      key={`caption-of-${col.key}`}
      x={xAttribute}
      y={yAttribute}
      dy={(options.captionProps(col).fontSize || 10) / 2}
      fill='#444'
      fontWeight='normal'
      {...options.captionProps(col)}
    >
      {/* col.caption */}
      {label.map((word, index) => (
        <tspan key={index} x={xPos} dy={index === 0 ? -14 : 14}>
          {word}
        </tspan>
      ))}
    </text>
  );
};

function wrapLabel(label, maxWidth) {
  const words = label.split(' ');
  const completedLines = [];
  let nextLine = '';
  words.forEach((word, index) => {
    const wordLength = getTextWidth(`${word} `);
    const nextLineLength = getTextWidth(nextLine);
    if (wordLength > maxWidth) {
      const { hyphenatedStrings, remainingWord } = breakString(word, maxWidth);
      completedLines.push(nextLine, ...hyphenatedStrings);
      nextLine = remainingWord;
    } else if (nextLineLength + wordLength >= maxWidth) {
      completedLines.push(nextLine);
      nextLine = word;
    } else {
      nextLine = [nextLine, word].filter(Boolean).join(' ');
    }
    const currentWord = index + 1;
    const isLastWord = currentWord === words.length;
    if (isLastWord) {
      completedLines.push(nextLine);
    }
  });
  return completedLines.filter((line) => line !== '');
}

function getTextWidth(text, font = '500 12px sans-serif') {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = font;
  return context.measureText(text).width;
}

function breakString(word, maxWidth, hyphenCharacter = '-') {
  const characters = word.split('');
  const lines = [];
  let currentLine = '';
  characters.forEach((character, index) => {
    const nextLine = `${currentLine}${character}`;
    const lineWidth = getTextWidth(nextLine);
    if (lineWidth >= maxWidth) {
      const currentCharacter = index + 1;
      const isLastLine = characters.length === currentCharacter;
      const hyphenatedNextLine = `${nextLine}${hyphenCharacter}`;
      lines.push(isLastLine ? nextLine : hyphenatedNextLine);
      currentLine = '';
    } else {
      currentLine = nextLine;
    }
  });
  return { hyphenatedStrings: lines, remainingWord: currentLine };
}

// const caption = options => col => (
//   <text
//     key={`caption-of-${col.key}`}
//     x={polarToX(col.angle, (options.size / 2) * 0.95).toFixed(4)}
//     y={polarToY(col.angle, (options.size / 2) * 0.95).toFixed(4)}
//     dy={(options.captionProps(col).fontSize || 10) / 2}
//     fill="#444"
//   	fontWeight="normal"
//     fontSize="4px"
//     {...options.captionProps(col)}
//   >
//     {/*col.caption*/}
//
//   </text>
// );

const render = (captions, chartData, options = {}) => {
  if ('object' !== typeof captions || Array.isArray(captions)) {
    throw new Error('caption must be an object');
  }
  if (!Array.isArray(chartData)) {
    throw new Error('data must be an array');
  }
  options.chartSize = options.size / options.zoomDistance;

  const columns = Object.keys(captions).map((key, i, all) => {
    return {
      key,
      caption: captions[key],
      angle: (Math.PI * 2 * i) / all.length,
    };
  });
  const groups = [<g key={`g-groups}`}>{chartData.map(shape(columns, options))}</g>];
  if (options.captions) {
    groups.push(<g key={`poly-captions`}>{columns.map(caption(options))}</g>);
  }
  if (options.dots) {
    groups.push(<g key={`g-dots`}>{chartData.map(dot(columns, options))}</g>);
  }
  if (options.axes) {
    groups.unshift(<g key={`group-axes`}>{columns.map(axis(options))}</g>);
  }
  if (options.scales > 0) {
    const scaleNumbers = [];
    const scaleNumberBGs = [];
    for (let i = options.scales; i > 0; i--) {
      // console.log(i);
      scaleNumbers.push(scaleNumber(options, i / options.scales, i));
      scaleNumberBGs.push(scaleNumberBG(options, i / options.scales));
      // // console.log(i);
      // // console.log(options, i / options.scales);
    }
    groups.unshift(<g key={`poly-scale-numbers`}>{scaleNumbers}</g>);
    groups.unshift(<g key={`poly-scale-number-bgs`}>{scaleNumberBGs}</g>);

    const scales = [];
    for (let i = options.scales; i > 0; i--) {
      scales.push(scale(options, i / options.scales));
    }
    groups.unshift(<g key={`poly-scales`}>{scales}</g>);
  }
  const delta = (options.size / 2).toFixed(4);
  return <g transform={`translate(${delta},${delta})`}>{groups}</g>;
};

export default render;
