import React, { Component } from 'react';

export default class RowResponsesColumnDF extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {responses, bold, distribution, distributionArray, ignoreResponses} = this.props
    const responseKeys = [5,4,3,2,1,0]
    return (
      <div className='w-30 mv1'>
        <div className='w-100 flex'>
          {!ignoreResponses && (
            distribution ? (
              distributionArray.map((portion, portionIndex) => {
                return <p key={portionIndex} className='w-100 mv0 tc'>{portion.toFixed(0)}%</p>;
              })
            ) : (
              responseKeys.map((key,i) => {
                return responses && (
                  <p key={i} className='w-100 mv0 tc'>
                    {bold ? <strong>{responses[key]}</strong> : responses[key]}
                  </p>
                )
              })
            )
          )}
        </div>
      </div>
    )
  }
}