import React from 'react';

export default class ReportPageTemplate extends React.Component {
  render() {

    const {pageNum, reportType} = this.props

    return <div
        className='report-footer'
        style={{
          backgroundColor: '#FFFFFF',
          position: 'absolute',
          bottom: '0',
          left: '0',
          padding: '2mm 10mm 3mm 10mm',
          width: '100%',
          borderTop: '1px solid #ccc',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <p style={{ fontFamily: 'Poppins-SemiBold', margin: '0px', padding: '0px', color: '#001e64' }}>{reportType}</p>
            <p style={{ fontFamily: 'Poppins-SemiBold', margin: '0px', padding: '0px', color: '#001e64' }}>Page {pageNum}</p>
        </div>
      </div>
  }
}
