import React, { Component } from 'react';
import { db } from '../firebase';
import { Link, withRouter } from 'react-router-dom';
import { projectTypeText } from '../helpers';
import RevieweeDetails from '../components/revieweedetails';
import Papa from 'papaparse';
import Modal from './global/modal';
import ReactModal from 'react-modal';
import exampleUsersCSV from '../users.csv';
import exampleUsersOnlyCSV from '../users-only.csv';
import exampleUsersAndReviewersCSV from '../users-and-reviewers.csv';
import withAuthorisation from '../components/withauthorisation';
import Autocomplete from 'react-autocomplete';

const authCondition = (authUser) => !!authUser;

class RunOptions extends Component {
  constructor(props) {
    super(props);

    this.openGroupModal = this.openGroupModal.bind(this);
    this.closeGroupModal = this.closeGroupModal.bind(this);
    this.onSubmitGroup = this.onSubmitGroup.bind(this);
    this.createEmptyGroup = this.createEmptyGroup.bind(this);
    this.createGroupFromCSV = this.createGroupFromCSV.bind(this);

    this.state = {
      clientLogo: '',
      loading: true,
      sendingEmails: false,
      showNewGroupModal: false,
      showNewGroupImportModal: false,
      currentGroupId: '',
      userGroups: {},
      questionnaireID: null,
      btnBrandCol: this.props.btnBrandCol,
    };
  }

  componentDidMount() {
    // db.doGetClientLogo(this.props.accountId, this.props.current360ID).then((logo) => {
    //   this.setState({ clientLogo: logo });
    // })

    db.doGet360QuestionniareID(this.props.accountId, this.props.current360ID).then((snapshot) => {
      if (snapshot.data()) {
        this.setState({
          questionnaireID: snapshot.data()['questionnaireID'],
        });
      }
      db.doGetUserGroups(this.props.accountId, this.props.current360ID).onSnapshot((snapshot) => {
        let groups = {};
        snapshot.docs.map((doc, i) => {
          groups[doc.id] = doc.data();
        });
        if (!this.props.current360.comparisonProject) {
          this.setState({
            userGroups: groups,
            loading: false,
          });
        } else {
          db.getPreviousGroups(this.props.accountId, this.props.current360.duplicatedFrom)
            .then((docs) => {
              let prevGroups = [];
              if (docs.size > 0) {
                docs.forEach((doc) => {
                  prevGroups.push({
                    id: doc.id,
                    data: doc.data(),
                  });
                });
                this.setState({
                  userGroups: groups,
                  loading: false,
                  groups: prevGroups,
                });
              } else {
                this.setState({
                  userGroups: groups,
                  loading: false,
                  groups: 'No Previous Groups On Linked Project',
                });
              }
            })
            .catch((err) => {
              // console.log(err);
            });
        }
      });
    });
  }
  openGroupModal(dupeCheck) {
    if (!dupeCheck) {
      this.setState({ showNewGroupModal: true });
    } else {
      this.setState({ showNewGroupImportModal: true });
    }
  }

  closeGroupModal() {
    this.setState({ showNewGroupModal: false });
  }

  createGroupFromCSV(groupTitle, users) {
    if (this.props.current360Type === '360') {
      // console.log(document.getElementById('lockReviewersCheck').checked);
      db.doCreate360Group(
        this.props.accountId,
        this.props.current360ID,
        groupTitle,
        users,
        this.state.questionnaireID,
        this.props.current360Relationships,
        document.getElementById('lockReviewersCheck').checked
      ).then((newGroupId) => {
        // console.log('created');
        this.setState({ showNewGroupModal: false });
        this.props.history.push(`/dashboard/${this.props.accountId}/360s/${this.props.current360ID}/edit/group/${newGroupId}/`);
      });
    } else if (this.props.current360Type === 'Team Assessment') {
      const teamMembers = {
        TM: { name: 'Team Members', colour: '#5b4898', description: 'Team Members consist of anyone who is part of the respective team' },
      };
      // email key required for check in importReviewersFromCSV function, as one isn't used for a team this will use groupTitle instead
      const teamUsers = [{ name: groupTitle, email: groupTitle }, ...users];
      db.doCreate360Team(this.props.accountId, this.props.current360ID, groupTitle, teamUsers, this.state.questionnaireID, teamMembers, false).then(
        (newGroupId) => {
          console.log('newGroupId', newGroupId);
          this.setState({ showNewGroupModal: false });
          this.props.history.push(`/dashboard/${this.props.accountId}/360s/${this.props.current360ID}/edit/group/${newGroupId}/`);
        }
      );
    } else {
      db.doCreate360Group(
        this.props.accountId,
        this.props.current360ID,
        groupTitle,
        users,
        this.state.questionnaireID,
        this.props.current360Relationships,
        true
      ).then((newGroupId) => {
        // console.log('created');
        this.setState({ showNewGroupModal: false });
        this.props.history.push(`/dashboard/${this.props.accountId}/360s/${this.props.current360ID}/edit/group/${newGroupId}/`);
      });
    }
  }

  createEmptyGroup(e, groupTitle) {
    e.preventDefault();
    // console.log('empty group');
    if (this.props.current360Type === '360') {
      db.doCreate360Group(
        this.props.accountId,
        this.props.current360ID,
        groupTitle,
        null,
        this.state.questionnaireID,
        this.props.current360Relationships,
        document.getElementById('lockReviewersCheck').checked
      ).then((newGroupId) => {
        this.setState({ showNewGroupModal: false });
        this.props.history.push(`/dashboard/${this.props.accountId}/360s/${this.props.current360ID}/edit/group/${newGroupId}/`);
      });
    } else if (this.props.current360Type === 'Team Assessment') {
      const teamMembers = {
        TM: { name: 'Team Members', colour: '#5b4898', description: 'Team Members consist of anyone who is part of the respective team' },
      };
      const teamUser = [{ name: groupTitle }];
      db.doCreate360Team(this.props.accountId, this.props.current360ID, groupTitle, teamUser, this.state.questionnaireID, teamMembers, false).then(
        (newGroupId) => {
          this.setState({ showNewGroupModal: false });
          this.props.history.push(`/dashboard/${this.props.accountId}/360s/${this.props.current360ID}/edit/group/${newGroupId}/`);
        }
      );
    } else {
      db.doCreate360Group(
        this.props.accountId,
        this.props.current360ID,
        groupTitle,
        null,
        this.state.questionnaireID,
        this.props.current360Relationships,
        true
      ).then((newGroupId) => {
        this.setState({ showNewGroupModal: false });
        this.props.history.push(`/dashboard/${this.props.accountId}/360s/${this.props.current360ID}/edit/group/${newGroupId}/`);
      });
    }
  }

  onSubmitGroup(e) {
    let groupTitle = this.title.value;
    if (!this.filesInput.files[0]) {
      // Create empty Group
      this.createEmptyGroup(e, groupTitle);
    } else {
      // Parse users from SVG + create Group
      Papa.parse(this.filesInput.files[0], {
        complete: (result) => {
          result.data.map((user, i) => {
            if (this.props.current360Type === 'Team Assessment') {
              result.data[i].Relationship = 'TM';
              result.data[i].Reviewee = groupTitle;
            }
            if (user.hasOwnProperty('Relationship') && user.Relationship.split('').length === 0) {
              delete result.data[i].Relationship;
            }
            if (user.hasOwnProperty('Reviewee') && user.Reviewee.split('').length === 0) {
              delete result.data[i].Reviewee;
            }
          });
          this.createGroupFromCSV(groupTitle, result.data);
        },
        skipEmptyLines: 'greedy',
        header: true,
      });
    }

    e.preventDefault();
  }

  importGroup(e) {
    let errorCheck;
    db.importPreviousGroup(this.props.accountId, this.props.current360.duplicatedFrom, this.state.groupName.id)
      .then((docs) => {
        if (docs.size > 0) {
          let revieweesToImport = {};
          docs.forEach((doc) => {
            // console.log(doc.data());
            revieweesToImport[doc.id] = doc.data();
          });

          db.doCreate360Group(
            this.props.accountId,
            this.props.current360ID,
            this.title.value,
            null,
            this.state.questionnaireID,
            this.props.current360Relationships,
            document.getElementById('lockReviewersCheck').checked
          ).then((newGroupId) => {
            let importUsers = async () =>
              Object.keys(revieweesToImport).forEach((itemKey, n) => {
                // console.log(revieweesToImport[itemKey]);
                let revieweeObj = { itemKey: revieweesToImport[itemKey] };
                db.importGroupAddDetails(
                  this.props.accountId,
                  itemKey,
                  revieweesToImport[itemKey],
                  this.props.current360ID,
                  newGroupId,
                  this.state.questionnaireID
                )
                  .then((addRes) => {
                    // console.log(addRes);
                  })
                  .catch((err) => {
                    // console.log(err);
                    errorCheck = err;
                    this.setState({
                      importError: err,
                    });
                  });
              });
            importUsers()
              .then(() => {
                setTimeout(() => {
                  // console.log(errorCheck);
                  if (errorCheck) {
                    // console.log(errorCheck);
                  } else {
                    this.props.history.push(`/dashboard/${this.props.accountId}/360s/${this.props.current360ID}/edit/group/${newGroupId}/`);
                  }
                }, 500);
              })
              .catch((err) => {
                // console.log(err);
              });
          });
        } else {
          // console.log('something');
          this.setState({ groupsError: 'There are no reviewees to import from that group' });
        }
      })
      .catch((err) => {
        // console.log(err);
      });
    e.preventDefault();
  }

  render() {
    const popupboxConfig = {
      titleBar: {
        enable: false,
      },
      fadeIn: true,
      fadeInSpeed: 500,
      overlayClose: true,
    };

    const projectType = this.props.current360Type;

    return (
      <div className='mv5'>
        <h2>
          Add {projectTypeText(projectType, 'participants', 'participants', 'teams')} to your {projectType} project
        </h2>
        {/* <p>
          You can add multiple {projectTypeText(projectType, 'groups/cohorts of participants (reviewees)', 'groups/cohorts of participants', 'teams')} within
          each project. There is no restriction to the number of {projectTypeText(projectType, 'groups', 'groups', 'teams')} you can add to each{' '}
          {projectTypeText(projectType, '360', '', '')} project, and each {projectTypeText(projectType, 'group', 'group', 'team')} will have its own deadline.
        </p> */}
        <p>
          You can add multiple groups/cohorts of {projectType === '360' ? 'reviewees' : 'participants'} within
          each project. There is no restriction to the number of groups you can add to each {projectType === '360' ? '360' : ''} project, and each group will have its own deadline.
        </p>
        {projectType !== 'Team Assessment' ? (
          <p>
            A participant {projectTypeText(projectType, '(reviewee)', '', '')} can only be in one group within a {projectTypeText(projectType, '360', '', '')}{' '}
            project, but may be added to multiple {projectTypeText(projectType, '360', '', '')} projects.
          </p>
        ) : (
          <></>
        )}

        <div className='w100 bg-white shadow pa4 mv4'>
          <p>
            {/* <strong>View/Edit Your {projectTypeText(projectType, 'Groups', 'Groups', 'Teams')}:</strong> */}
            <strong>View/Edit Your Groups:</strong>
          </p>
          {!this.state.loading
            ? Object.keys(this.state.userGroups)
                .reverse()
                .map((key) => {
                  let groupEditLink = `/dashboard/360s/${this.props.current360ID}/group/${key}/`;
                  return (
                    <div key={key} className='w-100 bg--pale flex justify-between items-center pa4 mb4'>
                      <p className='reset-mar'>
                        <strong>{this.state.userGroups[key].title}</strong>
                      </p>
                      <Link
                        style={this.props.btnBrandCol ? { color: `${this.props.btnBrandCol}` } : {}}
                        className='btn'
                        to={{
                          pathname: `group/${key}/`,
                          current360: this.props.current360,
                          accountData: this.props.accountData,
                          current360Groups: this.props.current360Groups,
                          current360Relationships: this.props.current360Relationships,
                        }}
                      >
                        View {projectTypeText(projectType, 'Group', 'Group', 'Team')}
                      </Link>
                    </div>
                  );
                })
            : ''}
          <div className='w-100'>
            <button
              style={this.props.btnBrandCol ? { backgroundColor: `${this.props.btnBrandCol}` } : {}}
              className='btn'
              onClick={(e) => this.openGroupModal(false)}
            >
              {/* Add New {projectTypeText(projectType, 'Group', 'Group', 'Team')} */}
              Add New Group
            </button>
            {this.props.current360.comparisonProject ? (
              <button
                style={this.props.btnBrandCol ? { backgroundColor: `${this.props.btnBrandCol}` } : {}}
                className='btn ml2'
                onClick={(e) => this.openGroupModal(true)}
              >
                Import Group From Previous 360
              </button>
            ) : (
              ''
            )}
          </div>
        </div>

        <Modal
          isOpen={this.state.showNewGroupModal}
          contentLabel='Add New Group'
          onClose={() => this.setState({ showNewGroupModal: false })}
          content={
            <div>
              <h2 style={this.props.btnBrandCol ? { color: `${this.props.btnBrandCol}` } : {}}>
                {/* Create New {projectTypeText(projectType, 'Group', 'Group', 'Team')} */}
                Create New Group
              </h2>
              <form
                className='mw6 center pt2 pb2'
                onSubmit={(e) => {
                  this.onSubmitGroup(e);
                }}
              >
                <div>
                  <label htmlFor='title'>Add {projectTypeText(projectType, 'group/cohort', 'group/cohort', 'team')} name</label>
                  <input
                    required
                    ref={(input) => (this.title = input)}
                    aria-describedby='name-desc'
                    className='input-reset ba b--black-20 pa2 mb2 db w-100'
                    type='text'
                    placeholder={`My ${projectTypeText(projectType, 'Group', 'Group', 'Team')}`}
                  />
                </div>
                <div className='mb2'>
                  <label htmlFor='filesInput'>(Optional) Upload Participants - CSV File</label>

                  <input
                    className='csv-input w-100'
                    type='file'
                    ref={(input) => {
                      this.filesInput = input;
                    }}
                    name='file'
                    accept='.csv'
                    placeholder={null}
                  />
                  {projectTypeText(projectType, [' (users-only)', ' (users and reviewers)'], [''], ['']).map((item, index) => {
                    return (
                      <p className='mt3'>
                        <a href={index > 0 ? exampleUsersAndReviewersCSV : exampleUsersOnlyCSV}>
                          <strong>Example CSV file{item}</strong>
                        </a>
                      </p>
                    );
                  })}
                </div>
                {projectType !== '360' ? (
                  ''
                ) : (
                  <>
                    <div className='w-100 flex'>
                      <p className='w-40 pt2' for='lockReviewersCheck'>
                        <strong>Lock Reviewers?*</strong>
                      </p>
                      <input className='w-40' id='lockReviewersCheck' type='checkbox' />
                    </div>
                    <p className='w-100 f7-ns'>
                      Locking the reviewers will prevent the reviewee from assigning additional reviewers once they have completed their self-review
                    </p>
                  </>
                )}

                <button className='btn' type='submit'>
                  Create {projectTypeText(projectType, 'Group', 'Group', 'Team')}
                </button>
              </form>
            </div>
          }
        />
        <Modal
          isOpen={this.state.showNewGroupImportModal}
          contentLabel='Add New Group'
          onClose={() => this.setState({ showNewGroupImportModal: false, importError: false })}
          content={
            !this.state.importError ? (
              <div>
                <h2 style={this.props.btnBrandCol ? { color: `${this.props.btnBrandCol}` } : {}}>Create/Import New Group</h2>
                <form
                  className='mw6 center pt2 pb2'
                  onSubmit={(e) => {
                    this.importGroup(e);
                  }}
                >
                  <div>
                    <label htmlFor='title'>Add group/cohort name</label>
                    <input
                      required
                      ref={(input) => (this.title = input)}
                      aria-describedby='name-desc'
                      className='input-reset ba b--black-20 pa2 mb2 db w-100'
                      type='text'
                      placeholder='My Group'
                    />
                  </div>

                  <div className='mb2'>
                    <Autocomplete
                      items={this.state.groups}
                      getItemValue={(item) => item.data.title}
                      renderItem={(item, isHighlighted) => (
                        <div
                          key={item.id}
                          className='input-reset bb bl br b--black-20 pa2 db w-100'
                          style={{ background: isHighlighted ? 'lightgray' : 'white' }}
                        >
                          {item.data.title}
                        </div>
                      )}
                      renderInput={(props) => (
                        <input
                          aria-describedby='client-desc'
                          {...props}
                          className='input-reset ba b--black-20 pa2 mb4 db w-100'
                          type='text'
                          placeholder='Select a Group/Cohort to Import'
                        />
                      )}
                      value={this.state.newGroupName}
                      onChange={(e) => this.setState({ groupName: { name: e.target.value, id: e.target.id } })}
                      onSelect={(e, n) => this.setState({ newGroupName: n.data.title, groupName: { name: n.data.title, id: n.id } })}
                      wrapperStyle={{
                        position: 'relative',
                        zIndex: '999',
                      }}
                      menuStyle={{
                        top: '100%',
                        left: 0,
                        position: 'absolute',
                      }}
                    />
                  </div>
                  <div className='w-100 flex'>
                    <p className='w-40 pt2' for='lockReviewersCheck'>
                      <strong>Lock Reviewers?*</strong>
                    </p>
                    <input className='w-40' id='lockReviewersCheck' type='checkbox' />
                  </div>
                  <p className='w-100 f7-ns'>
                    Locking the reviewers will prevent the reviewee from assigning additional reviewers once they have completed their self-review
                  </p>

                  <button className='btn' type='submit'>
                    Create Group
                  </button>
                </form>
              </div>
            ) : (
              <div className='w100'>
                <h2 className='tc' style={this.props.btnBrandCol ? { color: `${this.props.btnBrandCol}` } : {}}>
                  Import Error
                </h2>
                <p>The group has been create however there was an error importing some or all of the reviewees</p>
                <div className='w100 tc'>
                  <button className='btn tc' onClick={() => this.setState({ showNewGroupImportModal: false, importError: false })}>
                    Close
                  </button>
                </div>
              </div>
            )
          }
        />
      </div>
    );
  }
}

export default withRouter(RunOptions);
