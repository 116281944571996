import React, { Component } from 'react';
import RowRelationshipHeadingColumnDF from './RowRelationshipHeadingColumnDF';
import RowAveragesColumnDF from './RowAveragesColumnDF';
import RowResponsesColumnDF from './RowResponsesColumnDF';
import LoadingState from '../global/loading-state';

export default class ChartRowDF extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    const {distribution} = this.props
    if (distribution) {
      let distributionArray = [...this.props.distributionArray].reverse()
      this.setState({distributionArray, loading: false})
    } else {
      this.setState({loading: false})
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {chartRowDFLoadingUpdate} = this.props
    // when loading is complete, informs parent component so that it can gather correct height data
    if (!this.state.loading) {
      if (prevProps.reRenderStage !== this.props.reRenderStage) {
        if (chartRowDFLoadingUpdate && this.props.reRenderStage === 1) {
          chartRowDFLoadingUpdate()
        }
      }
    }
  }

  render() {
    const {wrap, headingContent, colour, distribution, colours, average, opacity, allReviewers, responses, bold, ignoreResponses, isSection, questionIndex, selfRow, reportType} = this.props
    const {loading, distributionArray} = this.state
    return !loading ? (
      <div className={`w-100 flex table__row ${wrap ? 'flex-wrap' : ''}`}>
        {reportType === 'Self' || reportType === 'Team' ? (
          <></>
        ) : (
          <RowRelationshipHeadingColumnDF content={headingContent} colour={colour} />
        )}
        <RowAveragesColumnDF distribution={distribution} distributionArray={distributionArray} colours={colours} average={average} valueColour={colour} barColour={colour} opacity={opacity} allReviewers={allReviewers} isSection={isSection} selfRow={selfRow} reportType={reportType} />
        <RowResponsesColumnDF distribution={distribution} distributionArray={distributionArray} responses={responses} bold={bold} ignoreResponses={ignoreResponses} questionIndex={questionIndex} />
      </div>
    ) : (
      <LoadingState />
    )
  }
}
