import React, { Component } from 'react';
import PageHeader from '../PageHeader';
import DottedLines from './DottedLines';

export default class DetailedFeedbackReflection extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {stepNumber} = this.props;
    return (
      <div
       className='mb5 page-break average-rating-section-reflection'
        style={{marginTop: '34.015px'}}
      >
        <PageHeader h2Class='mt4' h2Text='Part 2: ' strong={`Step ${stepNumber}`} h3Text={`Your detailed feedback`} line={true} />
        <h3 className='f4 mb1 pt3 mt1'>
          <strong>Reflection</strong>
        </h3>
        <h4 className='gray mb1'>What are the key themes or messages from the feedback in this section?</h4>
        <DottedLines numberOfLines={26} />
      </div>
    )
  }
}

