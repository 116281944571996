import React, { Component } from 'react';

export default class RowRelationshipHeadingColumnDF extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {content, colour} = this.props
    return (
        <div className='w-20 mv1'>
          {content && (
            <p
             className='ma0'
             style={{color: colour}}
            >
              <strong>{content}</strong>
            </p>
          )}
        </div>
      )
  }
}
