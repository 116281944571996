import React, { Component } from 'react';
import { auth, db } from './firebase';
import firebase from 'firebase/app';
import generator from 'generate-password';
import { getUnique, sleep } from '../helpers';
import moment from 'moment';

// User API
export const doCreateUser = (id, fname, lname, email, companyname, clientname, resetBrand, clientlogo, initialCredits) => {
  return db
    .collection(`admins`)
    .doc(id)
    .set({
      fname: fname,
      lname: lname,
      email: email.replace(/\s+/g, '').toLowerCase(),
      accounts: [
        {
          id: id,
          title: `${companyname}`,
        },
      ],
    })
    .then(() => {
      db.collection(`accounts`)
        .doc(id)
        .set({
          title: `${companyname}`,
          clientname: clientname,
          logo: clientlogo,
          resetbrand: resetBrand,
          credits: initialCredits,
        });
    });
};

// Check for user account in adminUsers (from access/set password page)
export const doGetAdminUserAccount = (accountID, userEmail) => {
  return new Promise(function(resolve, reject) {
    db.collection(`accounts`)
      .doc(accountID)
      .get()
      .then((snapshot) => {
        let accountData = snapshot.data();

        if (accountData && accountData.adminUsers.some((obj) => obj.email === userEmail)) {
          resolve(accountData.adminUsers[accountData.adminUsers.findIndex((x) => x.email === userEmail)]);
        } else {
          reject();
        }
      });
  });
};

// User API for invited accounts
export const doCreateAccessUser = (id, fname, lname, email, accountID) => {
  return new Promise(function(resolve, reject) {
    db.collection(`accounts`)
      .doc(accountID)
      .get()
      .then((snapshot) => {
        let accountData = snapshot.data();

        db.collection(`admins`)
          .doc(id)
          .set({
            fname: fname,
            lname: lname,
            email: email.replace(/\s+/g, '').toLowerCase(),
            accounts: [
              {
                id: accountID,
                title: accountData['title'],
              },
            ],
          })
          .then(() => {
            resolve();
          });
      });
  });
};

export const doRevokeUserAccess = (currentAccountID, userObjToRevokeAccess, accountObjToRevokeAccess) => {
  console.log('doRevokeUserAccess');
  console.log(currentAccountID, userObjToRevokeAccess, accountObjToRevokeAccess);
  return new Promise(function(resolve, reject) {
    db.collection(`accounts`)
      .doc(currentAccountID)
      .update({
        adminUsers: firebase.firestore.FieldValue.arrayRemove(userObjToRevokeAccess),
      })
      .then((data) => {
        // Remove reference of accoutn from user:
        db.collection(`admins`)
          .doc(userObjToRevokeAccess['id'])
          .update({
            accounts: firebase.firestore.FieldValue.arrayRemove(accountObjToRevokeAccess),
          })
          .then((data) => {
            resolve();
          });
      });
  });
};

// Alternate User accounts
export const doCreateAlternateAdminUser = (currentAccountID, accountTitle, currentAdminUsers, fname, lname, email) => {
  return new Promise(function(resolve, reject) {
    // first, check if admin account already exists for this user
    db.collection(`admins`)
      .where('email', '==', email)
      .get()
      .then((snapshot) => {
        if (!snapshot.empty) {
          // User exists, update account with new accountID
          console.log('user account record exists in DB for this email, updating');
          let userAccountId;
          let newUserAccount = {
            id: currentAccountID,
            title: accountTitle,
          };

          snapshot.docs.map((doc, i) => {
            // Ensure we only get the first result...
            if (i === 0) {
              userAccountId = doc.id;
            }
          });

          if (userAccountId) {
            let currentAccountUserDetails = {
              id: userAccountId,
              fname: fname,
              lname: lname,
              email: email,
            };

            db.collection(`admins`)
              .doc(userAccountId)
              .update(
                {
                  accounts: firebase.firestore.FieldValue.arrayUnion(newUserAccount),
                },
                { merge: true }
              )
              .then(() => {
                db.collection(`accounts`)
                  .doc(currentAccountID)
                  .update(
                    {
                      adminUsers: firebase.firestore.FieldValue.arrayUnion(currentAccountUserDetails),
                    },
                    { merge: true }
                  )
                  .then(() => {
                    resolve(true);
                  });
              });
          } else {
            reject();
          }
        } else {
          console.log('no user account record exists in DB');
          let currentAccountUserDetails = {
            fname: fname,
            lname: lname,
            email: email,
          };
          db.collection(`accounts`)
            .doc(currentAccountID)
            .update(
              {
                adminUsers: firebase.firestore.FieldValue.arrayUnion(currentAccountUserDetails),
              },
              { merge: true }
            )
            .then(() => {
              resolve(false);
            });
        }
      });
  });
  // return db.collection(`admins`).doc(id).set(
  //   {
  //     fname: fname,
  //     lname: lname,
  //     email: email,
  //     accounts: [{
  //       id: id,
  //       title: `${companyname}`,
  //     }]
  //   }
  // )
  // .then(() => {
  //   db.collection(`accounts`).doc(id).set(
  //     {
  //       title: `${companyname}`,
  //       clientname: clientname,
  //       logo: clientlogo,
  //       resetbrand: resetBrand,
  //       initialCredits: initialCredits
  //     })
  // })
};

export const doCreateNonAdminUser = (accountID, forename, surname, email, reviewerOnly) => {
  let userData = {};

  return new Promise(function(resolve, reject) {
    if (!email) {
      reject('Email invalid');
    } else {
      // search for user in
      db.collection(`accounts`)
        .doc(accountID)
        .collection(`users`)
        .where('email', '==', email.replace(/\s+/g, '').toLowerCase())
        .get()
        .then((snapshot) => {
          if (!snapshot.empty) {
            // User exists
            snapshot.docs.map((doc, i) => {
              //if the user is a 'reviewer only' received a request to change it
              //then change it
              if (reviewerOnly !== true) {
                doc.ref.update({
                  reviewer: false,
                });
              }
              // Ensure we only get the first result...
              if (i === 0) {
                userData[doc.id] = doc.data();
              }
            });
            resolve(userData);
          } else {
            console.log('Creating new user - ' + forename + ' ' + surname + ' - ' + email);
            console.log(accountID);
            db.collection(`accounts`)
              .doc(accountID)
              .collection(`users`)
              .add({
                forename: forename,
                surname: surname,
                email: email.replace(/\s+/g, '').toLowerCase(),
                reviewer: `${reviewerOnly}`,
              })
              .then((snapshot) => {
                db.collection(`accounts`)
                  .doc(accountID)
                  .collection(`users`)
                  .doc(snapshot.id)
                  .get()
                  .then((snapshot) => {
                    userData[snapshot.id] = snapshot.data();
                    console.log('userData');
                    console.log(userData);
                    resolve(userData);
                  });
              });

            // db.ref(`accounts/${accountID}/users`).on('child_added', snapshot => {
            //
            //   resolve(
            //     { [snapshot.key]: snapshot.val() }
            //   )
            //   db.ref(`accounts/${accountID}/users`).off('child_added');
            // })
            //
            // db.ref(`accounts/${accountID}/users`).push({
            //   forename,
            //   surname,
            //   email
            // })
          }
        });
    }
  });
};

export const doCreateTeamUser = (accountID, name) => {
  let userData = {};
  const forename = name.toLowerCase().replace(/(team)|\s/g, '');
  const surname = 'team';
  const email = 'no-reply@boomerang-360.com';

  return new Promise(function(resolve, reject) {
    db.collection(`accounts`)
      .doc(accountID)
      .collection(`users`)
      .where('name', '==', name)
      .get()
      .then(() => {
        db.collection(`accounts`)
          .doc(accountID)
          .collection(`users`)
          .add({
            name: name,
            forename: forename,
            surname: surname,
            email: email,
          })
          .then((snapshot) => {
            db.collection(`accounts`)
              .doc(accountID)
              .collection(`users`)
              .doc(snapshot.id)
              .get()
              .then((snapshot) => {
                userData[snapshot.id] = snapshot.data();
                resolve(userData);
              });
          });
      });
  });
};

export const checkUserIsNotInAnotherGroup = (accountID, userID, current360Id) => {
  console.log('checkUserIsNotInAnotherGroup');
  // return new Promise(function(resolve, reject) {
  //
  //   db.ref(`accounts/${accountID}/360s/${current360Id}/groups`).orderByChild(`email`).equalTo(email).once("value",snapshot => {
  //
  //   })
  //
  // })
};

export const doCreate360 = (accountID, title, clientName, new360Type, accountData, quickThreeSixty) => {
  console.log('new360Type =');
  console.log(new360Type);
  return db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`360s`)
    .add({
      type: new360Type,
      title: title,
      client: clientName,
      createdDate: Date.now(),
      expirationDate: null,
      archived: false,
      userIDs: [],
      statusCode: quickThreeSixty || new360Type == 'Goal Tracker' ? 1 : 0,
    })
    .then((data) => {
      let threesixtyKey = data.id;

      // add settings
      db.collection(`accounts`)
        .doc(accountID)
        .collection(`360s`)
        .doc(threesixtyKey)
        .set(
          {
            settings: {
              logo: accountData.logo,
              resetbrand: accountData.resetbrand,
            },
          },
          { merge: true }
        );

      // add relationships
      db.collection('accounts')
        .doc(accountID)
        .collection('360s')
        .doc(threesixtyKey)
        .collection('relationships')
        .doc('DR')
        .set({
          name: 'Direct Report',
          colour: '#54b99c',
          description: 'Direct Reports are people who report into you; you are their line manager.',
          sortOrder: 0,
        });
      db.collection('accounts')
        .doc(accountID)
        .collection('360s')
        .doc(threesixtyKey)
        .collection('relationships')
        .doc('LM')
        .set({
          name: 'Line Manager',
          colour: '#dcdc00',
          description: 'Line Managers are people who you report into; they are your line manager/s.',
          sortOrder: 1,
        });
      db.collection('accounts')
        .doc(accountID)
        .collection('360s')
        .doc(threesixtyKey)
        .collection('relationships')
        .doc('O')
        .set({
          name: 'Other',
          colour: '#1c9ad7',
          description:
            'Other Reports are people who you work with on a regular basis; they may be colleagues in other departments or external suppliers/customers.',
          sortOrder: 2,
        });
      db.collection('accounts')
        .doc(accountID)
        .collection('360s')
        .doc(threesixtyKey)
        .collection('relationships')
        .doc('P')
        .set({
          name: 'Peer',
          colour: '#5b4898',
          description: 'Peers are people who operate at the same level as you.',
          sortOrder: 3,
        });

      // add reminders
      let reminders = [
        {
          title: 'Complete your self-review',
          body_text: 'A new self review has been created for you!',
          button_text: 'Complete your self-review',
        },
        {
          title: 'Your self-review is waiting',
          body_text: 'A new self review has been created for you!',
          button_text: 'Complete your self-review',
        },
        {
          title: 'Your self-review is waiting',
          body_text: 'A new self review has been created for you!',
          button_text: 'Complete your self-review',
        },
        {
          title: 'Final reminder - complete your self-review',
          body_text: 'A new self review has been created for you!',
          button_text: 'Complete your self-review',
        },
      ];

      if (new360Type == 'Goal Tracker') {
        reminders = [
          {
            title: 'Complete goal',
            body_text: 'Please complete your latest Goal',
            button_text: 'Click here to complete your Goal',
          },
        ];
      }

      db.collection('accounts')
        .doc(accountID)
        .collection('360s')
        .doc(threesixtyKey)
        .collection('email_text')
        .doc('reminders')
        .set(
          {
            ...reminders,
          },
          { merge: true }
        );

      // return the full 360 object
      return db
        .collection(`accounts`)
        .doc(accountID)
        .collection(`360s`)
        .doc(threesixtyKey)
        .get();
    });

  // object360
  // return db.ref(`accounts/${accountID}/360s`).push(
  //   object360
  // );
};

export const doCreateNewQuestionnaire = (accountID, new360Type) => {
  let questionnaireObj = {
    type: new360Type,
  };

  if (new360Type == 'Goal Tracker') {
    console.log('initialise goaltracker steps');
    let sectionObj = {
      title: 'Goal Number 1',
      description: '',
    };

    let questionsObj = [
      {
        answerType: 'FreeText',
        questionTitle: 'My goal is',
        required: true,
        sortOrder: 0,
        id: '1632815630662',
      },
      {
        answerType: 'FreeText',
        questionTitle: 'Specific actions needed to deliver my goal',
        required: true,
        sortOrder: 1,
        id: '1632815640662',
      },
      {
        answerType: 'FreeText',
        questionTitle: 'I will measure my success by',
        required: true,
        sortOrder: 2,
        id: '1632815650662',
      },
      {
        answerType: 'FreeText',
        questionTitle: 'The support I need',
        required: true,
        sortOrder: 3,
        id: '1632815660662',
      },
      {
        answerType: 'Date',
        questionTitle: 'When will I review my progress',
        required: true,
        sortOrder: 4,
        id: '1632815670662',
      },
      {
        answerType: 'FreeText',
        questionTitle: 'My progress to date',
        required: true,
        sortOrder: 5,
        id: '1632815680662',
      },
      {
        answerType: 'Date',
        questionTitle: 'My deadline is',
        required: true,
        sortOrder: 6,
        id: '1632815690662',
      },
    ];

    console.log(Object.keys(questionsObj).length);
    return new Promise(function(resolve, reject) {
      db.collection(`accounts`)
        .doc(accountID)
        .collection(`questionnaires`)
        .add({
          type: new360Type,
        })
        .then((snapshot) => {
          let questionnaireRef = snapshot;
          let questionnaireKey = snapshot.id;

          let questionNum = 0;
          let secID = `${new Date().getTime()}`;
          db.collection(`accounts`)
            .doc(accountID)
            .collection(`questionnaires`)
            .doc(questionnaireKey)
            .collection(`sections`)
            .doc(secID)
            .set(
              {
                title: sectionObj['title'],
                description: sectionObj['description'],
                created: firebase.firestore.FieldValue.serverTimestamp(),
                sortOrder: 0,
              },
              { merge: false }
            )
            .then((snapshot) => {
              let sectionKey = secID;

              Object.keys(questionsObj).map((question, i) => {
                // db.collection(`accounts`).doc(accountID).collection(`questionnaires`).doc(questionnaireKey).collection(`sections`).doc(sectionKey).collection(`questions`).add({
                setTimeout(() => {
                  db.collection(`accounts`)
                    .doc(accountID)
                    .collection(`questionnaires`)
                    .doc(questionnaireKey)
                    .collection(`sections`)
                    .doc(sectionKey)
                    .collection(`questions`)
                    .doc(`${questionsObj[question]['id']}`)
                    .set(
                      {
                        answerType: questionsObj[question]['answerType'],
                        questionTitle: questionsObj[question]['questionTitle'],
                        required: questionsObj[question]['required'],
                        id: questionsObj[question]['id'],
                        sortOrder: questionsObj[question]['sortOrder'],
                      },
                      { merge: false }
                    )
                    .then((snapshot) => {
                      questionNum++;

                      if (questionNum == Object.keys(questionsObj).length) {
                        resolve(questionnaireRef);
                      }
                    });
                }, i * 250);
              });
            });
        });
    });

    // db.collection(`accounts`).doc(accountID).collection(`questionnaires`).doc(questionnaireID).collection(`sections`).add({
    //   title: quickThreeSixtyObject[sectionKey]['title'],
    //   description: quickThreeSixtyObject[sectionKey]['description']
    // }, { merge: false }).then((snapshot) => {

    // return new Promise(function(resolve, reject) {
    //   console.log('aaaaaaaaaaa');
    //   db.ref(`accounts/${accountID}/questionnaires`).push(questionnaireObj).then(data => {
    //     let questionnaire = data
    //     let questionnaireKey = data.key
    //     db.ref(`accounts/${accountID}/questionnaires/${questionnaireKey}/sections`).push(sectionObj).then(data => {
    //       let sectionKey = data.key
    //       Object
    //         .keys(questionsObj)
    //         .map(key => {
    //           db.ref(`accounts/${accountID}/questionnaires/${questionnaireKey}/sections/${sectionKey}/questions/`).push(questionsObj[key])
    //         })
    //
    //       resolve(questionnaire)
    //
    //     })
    //   }).catch((error) => {
    //     console.log(error);
    //   });
    // })
  } else {
    return db
      .collection(`accounts`)
      .doc(accountID)
      .collection(`questionnaires`)
      .add({
        type: new360Type,
      });
  }
};

export const updateSection = (accountID, questionnaireID, sectionID, sectionData) => {
  // uses .set() with {merge} to update fields of a document, so we can set the fields with correct sortOrder values
  return db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`questionnaires`)
    .doc(questionnaireID)
    .collection('sections')
    .doc(sectionID)
    .set(
      {
        ...sectionData,
      },
      { merge: true }
    );
};

export const doDeleteRelationship = (accountID, current360Id, originalKey) => {
  return db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`360s`)
    .doc(current360Id)
    .collection(`relationships`)
    .doc(originalKey)
    .delete();
};

export const doCreateNewRelationship = (accountID, current360Id, newRelationshipKey, relationshipData, sortOrder) => {
  return db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`360s`)
    .doc(current360Id)
    .collection(`relationships`)
    .doc(newRelationshipKey)
    .set(
      {
        ...relationshipData,
        sortOrder,
      },
      { merge: true }
    );
};

export const updateReviewerRecievedInfo = (accountID, reviewerID, current360ID, groupID, userID) => {
  db.collection(`accounts`)
    .doc(accountID)
    .collection(`reviewers`)
    .doc(reviewerID)
    .set(
      {
        recievedDate: Date.now(),
        emailRecieved: true,
        notificationCount: 1,
      },
      { merge: true }
    );

  db.collection(`accounts`)
    .doc(accountID)
    .collection(`360s`)
    .doc(current360ID)
    .collection(`groups`)
    .doc(groupID)
    .collection(`users`)
    .doc(userID)
    .collection(`reviewers`)
    .doc(reviewerID)
    .set(
      {
        emailRecieved: true,
      },
      { merge: true }
    );
};

export const doUpdateDeadlineDate = (accountID, current360ID, groupID, date) => {
  return db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`360s`)
    .doc(current360ID)
    .collection(`groups`)
    .doc(groupID)
    .set(
      {
        deadlineDate: date,
      },
      { merge: true }
    );
};

export const doUpdateRelationship = (accountID, current360Id, originalKey, relationshipData, sortOrder) => {
  return db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`360s`)
    .doc(current360Id)
    .collection(`relationships`)
    .doc(originalKey)
    .set(
      {
        ...relationshipData,
        sortOrder,
      },
      { merge: true }
    );
};

export const doUpdateEmailText = (accountID, current360Id, originalKey, emailData) => {
  return db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`360s`)
    .doc(current360Id)
    .collection(`email_text`)
    .doc(`reminders`)
    .set(
      {
        [[originalKey]]: emailData,
      },
      { merge: true }
    );
};
export const doUpdateReviewerEmailText = (accountID, current360Id, originalKey, emailData) => {
  return db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`360s`)
    .doc(current360Id)
    .collection(`reviewer_email_text`)
    .doc(`reminders`)
    .set(
      {
        [[originalKey]]: emailData,
      },
      { merge: true }
    );
};
export const doGetCurrent360EmailText = (accountID, current360Id) => {
  return db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`360s`)
    .doc(current360Id)
    .collection(`email_text`)
    .doc(`reminders`);
};
export const doGetCurrent360ReviewerEmailText = (accountID, current360Id) => {
  return db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`360s`)
    .doc(current360Id)
    .collection(`reviewer_email_text`)
    .doc(`reminders`);
};

export const doUpdate360Branding = (accountID, current360Id, brandingData) => {
  return db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`360s`)
    .doc(current360Id)
    .set(
      {
        ...brandingData,
      },
      { merge: true }
    );
};

export const doUpdateAccountData = (accountID, accountData) => {
  return db
    .collection(`accounts`)
    .doc(accountID)
    .set(
      {
        ...accountData,
      },
      { merge: true }
    );
};

export const doUpdateSectionTitle = (accountID, questionnaireRef, sectionRef, newTitle) => {
  let updatedTitle = {
    title: newTitle,
  };
  return db
    .collection(`accounts`)
    .doc(`${accountID}`)
    .collection(`questionnaires`)
    .doc(`${questionnaireRef}`)
    .collection(`sections`)
    .doc(`${sectionRef}`)
    .update(updatedTitle);
};

export const doUpdateSectionDesc = (accountID, questionnaireRef, sectionRef, newDescription) => {
  let updatedDescription = {
    description: newDescription,
  };
  return db
    .collection(`accounts`)
    .doc(`${accountID}`)
    .collection(`questionnaires`)
    .doc(`${questionnaireRef}`)
    .collection(`sections`)
    .doc(`${sectionRef}`)
    .update(updatedDescription);
};

export const getUserFromGroup = (accountID, userID) =>
  db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`users`)
    .doc(userID);

// Check user by email
export const doGetUserByEmail = (email) => auth.getUserByEmail(email);

export const assignReviewerToReviewee = (accountID, reviewerID, reviewerFullname, current360ID, groupID, userID, relationship, reviewerEmail) => {
  return db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`360s`)
    .doc(current360ID)
    .collection(`groups`)
    .doc(groupID)
    .collection(`users`)
    .doc(userID)
    .collection(`reviewers`)
    .doc(reviewerID)
    .set(
      {
        reviewerID: reviewerID,
        relationship: relationship,
        completed: false,
        name: reviewerFullname,
        email: reviewerEmail.replace(/\s+/g, '').toLowerCase(),
      },
      { merge: true }
    );
};

export const createNewReviewer = (accountID, revieweeName, name, emailAddress, relationship, current360, groupID, currentQuestionnaireID, userID) => {
  return new Promise(function(resolve, reject) {
    let hasSelfReview = false;
    let currentReviewerID = null;

    // db.ref(`accounts/${accountID}/users`).orderByChild(`email`).equalTo(emailAddress).once("value",snapshot => {
    db.collection(`accounts`)
      .doc(accountID)
      .collection(`users`)
      .where('email', '==', emailAddress)
      .get()
      .then((snapshot) => {
        if (!snapshot.empty) {
          hasSelfReview = true;
          snapshot.docs.map((doc, i) => {
            currentReviewerID = doc.id;
          });
        }

        let reviewerObj = {
          emailRecieved: false,
          notificationCount: 0,
          name: name,
          email: emailAddress,
          relationship: relationship,
          current360ID: current360,
          questionnaireID: currentQuestionnaireID,
          groupID: groupID,
          userID: userID,
          hasSelfReview: hasSelfReview,
          currentReviewerID: currentReviewerID,
          currentRevieweeName: revieweeName,
        };
        // db.ref(`accounts/${accountID}/reviewers`).push(

        db.collection(`accounts`)
          .doc(accountID)
          .collection(`reviewers`)
          .add(reviewerObj)
          .then((snapshot) => {
            let reviewerKey = snapshot.id;

            db.collection(`accounts`)
              .doc(accountID)
              .collection(`reviewers`)
              .doc(reviewerKey)
              .collection(`review`)
              .doc(`status`)
              .set(
                {
                  completed: false,
                  completionPercentage: 0,
                },
                { merge: true }
              )
              .then((snapshot) => {
                let fullName = name.split(' ');
                doCreateNonAdminUser(
                  accountID,
                  fullName[0].replace(/\s+/g, '').toLowerCase(),
                  fullName[1].replace(/\s+/g, '').toLowerCase(),
                  emailAddress,
                  false
                )
                  .then(() => {
                    resolve(reviewerKey);
                  })
                  .catch((err) => {
                    resolve(err);
                  });
              });

            // "review": {
            //   "completed": false,
            //   "completionPercentage": 0,
            //   "sections": false,
            // },
          });
      });
  });
};

export const doGetCurrentGroup = (accountID, current360Id, currentGroupId) =>
  db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`360s`)
    .doc(current360Id)
    .collection(`groups`)
    .doc(currentGroupId);

export const doGetCurrentGroupUsers = (accountID, current360Id, currentGroupId) =>
  db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`360s`)
    .doc(current360Id)
    .collection(`groups`)
    .doc(currentGroupId)
    .collection(`users`);

export const doGetCurrentGroupUserReviewers = (accountID, current360Id, currentGroupId, userID) =>
  db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`360s`)
    .doc(current360Id)
    .collection(`groups`)
    .doc(currentGroupId)
    .collection(`users`)
    .doc(userID)
    .collection(`reviewers`);

export const getEmailReminderText = (accountID, current360Id) =>
  db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`360s`)
    .doc(current360Id)
    .collection(`email_text`)
    .doc(`reminders`)
    .get();

export const doGetCurrentGroupTasks = (accountID, current360Id, currentGroupId) =>
  db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`360s`)
    .doc(current360Id)
    .collection(`groups`)
    .doc(currentGroupId)
    .collection(`tasks`);

export const getQuestionnaireSections = (accountID, questionnaireId) =>
  db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`questionnaires`)
    .doc(questionnaireId)
    .collection(`sections`)
    .orderBy(`created`);

export const doGetSectionsList = (accountID, questionnaireID) => {
  return db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`questionnaires`)
    .doc(questionnaireID)
    .collection('sections');
};

export const getQuestions = (accountID, questionnaireID, sectionID) =>
  db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`questionnaires`)
    .doc(questionnaireID)
    .collection(`sections`)
    .doc(sectionID)
    .collection(`questions`)
    .get();

export const doGetSectionsListOrdered = (accountID, questionnaireID) => {
  return (
    db
      .collection(`accounts`)
      .doc(accountID)
      .collection(`questionnaires`)
      .doc(questionnaireID)
      .collection('sections')
      // .orderBy(`created`);
      .orderBy(`sortOrder`)
  );
};

const importReviewersFromCSV = (group, reviewerUsersToParse, validUserKeys, accountID, current360Id, newGroupId, questionnaireID, relationships) => {
  // removes any duplicate reviewers by mapping the emails and using them to crete a set
  let uniqueReviewerEmails = new Set(reviewerUsersToParse.map((rev) => rev.Email));
  let UniqueReviewers = [];
  // finds the corresponding reviewer for each unique email and pushes to an array
  uniqueReviewerEmails.forEach((item) => {
    UniqueReviewers.push(reviewerUsersToParse.find((r) => r.Email == item));
  });
  // conditionally selects the unique reviewers if part of a team assessment
  let reviewersToParse = Object.keys(relationships).length < 2 && Object.keys(relationships)[0] === 'TM' ? UniqueReviewers : reviewerUsersToParse;
  return new Promise(function(resolve, reject) {
    if (reviewersToParse.length && validUserKeys.length) {
      Object.keys(validUserKeys).map((validUserKey) => {
        // create Reviewers!
        Object.keys(reviewersToParse).map((reviewerKey, i) => {
          if (
            reviewersToParse[reviewerKey]['Reviewee'].replace(/\s+/g, '').toLowerCase() ==
            group['users'][validUserKeys[validUserKey]]['email'].replace(/\s+/g, '').toLowerCase()
          ) {
            if (reviewersToParse[reviewerKey]['Relationship'] in relationships) {
              const userName = reviewersToParse[reviewerKey]['Relationship'] === 'TM'
                ?
                  group['users'][validUserKeys[validUserKey]].name 
                : 
                  `${group['users'][validUserKeys[validUserKey]]['forename']} ${group['users'][validUserKeys[validUserKey]]['surname']}`
              createNewReviewer(
                accountID,
                userName,
                `${reviewersToParse[reviewerKey]['Forename'].replace(/[^A-Za-z0-9|-]/g, '')} ${reviewerUsersToParse[reviewerKey]['Surname'].replace(
                  /[^A-Za-z0-9|-]/g,
                  ''
                )}`,
                reviewersToParse[reviewerKey]['Email'].replace(/\s+/g, '').toLowerCase(),
                reviewersToParse[reviewerKey]['Relationship'],
                current360Id,
                newGroupId,
                questionnaireID,
                validUserKeys[validUserKey]
              ).then((reviewerID) => {
                if (reviewerID) {
                  assignReviewerToReviewee(
                    accountID,
                    reviewerID,
                    `${reviewersToParse[reviewerKey]['Forename'].replace(/[^A-Za-z0-9|-]/g, '')} ${reviewerUsersToParse[reviewerKey]['Surname'].replace(
                      /[^A-Za-z0-9|-]/g,
                      ''
                    )}`,
                    current360Id,
                    newGroupId,
                    validUserKeys[validUserKey],
                    reviewersToParse[reviewerKey]['Relationship'],
                    reviewersToParse[reviewerKey]['Email'].replace(/\s+/g, '').toLowerCase()
                  ).then(() => {
                    if (i == reviewersToParse.length - 1) {
                      resolve();
                    }
                  });
                }
              });
            }
          }
        });
      });
    } else {
      resolve();
    }
  });
};

export const doCreate360Group = (accountID, current360Id, title, users, questionnaireID, relationships, lockedReviewer) => {
  let oneWeekFromNow = new Date();
  oneWeekFromNow.setDate(oneWeekFromNow.getDate() + 7);

  let revieweeUsers = [];
  let reviewersUsers = [];

  let group = {};
  group['title'] = title;
  group['statusCode'] = 0;
  group['activeTask'] = 1;
  group['createdDate'] = Date.now();
  group['tasks'] = {
    '1': {
      dueDate: Date.parse(oneWeekFromNow),
      completedDate: false,
      user: false,
    },
    '2': {
      dueDate: false,
      completedDate: false,
      user: false,
    },
    '3': {
      dueDate: false,
      completedDate: false,
      user: false,
    },
    '4': {
      dueDate: false,
      completedDate: false,
      user: false,
    },
  };

  group['users'] = {};

  return new Promise(function(resolve, reject) {
    if (users) {
      let usersComplete = false;

      Object.keys(users).map((key) => {
        if (users[key]['Relationship'] && users[key]['Relationship'] != '') {
          reviewersUsers.push(users[key]);
        } else {
          revieweeUsers.push(users[key]);
        }
      });

      // user GetUnique to remove possible duplicate email addresses / users
      let usersToParse = getUnique(revieweeUsers, 'Email');
      let reviewerUsersToParse = reviewersUsers;

      let checkComplete = () => {
        if (Object.keys(group['users']).length == usersToParse.length) {
          return true;
        } else {
          return false;
        }
      };

      console.log('usersToParse', usersToParse);

      usersToParse.map((parsedUser, i, arr) => {
        doCreateNonAdminUser(
          accountID,
          parsedUser['Forename'].replace(/\s+/g, '').toLowerCase(),
          parsedUser['Surname'].replace(/\s+/g, '').toLowerCase(),
          parsedUser['Email'].replace(/\s+/g, '').toLowerCase()
        ).then((userData) => {
          Object.keys(userData).map((key) => {
            let groupUserData = {
              email: userData[key]['email'].replace(/\s+/g, '').toLowerCase(),
              forename: userData[key]['forename'].replace(/\s+/g, '').toLowerCase(),
              surname: userData[key]['surname'].replace(/\s+/g, '').toLowerCase(),
            };
            group['users'][key] = groupUserData;
          });

          if (checkComplete()) {
            db.collection(`accounts`)
              .doc(accountID)
              .collection(`360s`)
              .doc(current360Id)
              .collection(`groups`)
              .add({
                title: group['title'],
                statusCode: group['statusCode'],
                activeTask: group['activeTask'],
                createdDate: group['createdDate'],
                locked: lockedReviewer,
              })
              .then((data) => {
                let newGroupId = data.id;
                // add all tasks:
                let batch = db.batch();
                Object.keys(group['tasks']).map((key) => {
                  var docRef = db
                    .collection(`accounts`)
                    .doc(accountID)
                    .collection(`360s`)
                    .doc(current360Id)
                    .collection(`groups`)
                    .doc(newGroupId)
                    .collection(`tasks`)
                    .doc(key); //automatically generate unique id
                  batch.set(docRef, {
                    dueDate: group['tasks'][key].dueDate,
                    completedDate: group['tasks'][key].completedDate,
                    user: group['tasks'][key].user,
                  });
                });

                batch.commit().then(function() {
                  let validUserKeys = [];

                  db.collection(`accounts`)
                    .doc(accountID)
                    .collection(`360s`)
                    .doc(current360Id)
                    .get()
                    .then((snapshot) => {
                      if (snapshot.data()['userIDs']) {
                        let userIDs = snapshot.data()['userIDs'];
                        Object.keys(group['users']).map((revieweeKey) => {
                          if (!userIDs.includes(revieweeKey)) {
                            validUserKeys.push(revieweeKey);
                          }
                        });
                      } else {
                        validUserKeys = group['users'];
                      }

                      if (validUserKeys.length) {
                        Object.keys(validUserKeys).map((validUserKey, i) => {
                          db.collection('accounts')
                            .doc(accountID)
                            .collection('360s')
                            .doc(current360Id)
                            .collection('groups')
                            .doc(newGroupId)
                            .collection('users')
                            .doc(validUserKeys[validUserKey])
                            .set(
                              {
                                email: group['users'][validUserKeys[validUserKey]]['email'].replace(/\s+/g, '').toLowerCase(),
                                forename: group['users'][validUserKeys[validUserKey]]['forename'].replace(/\s+/g, '').toLowerCase(),
                                surname: group['users'][validUserKeys[validUserKey]]['surname'].replace(/\s+/g, '').toLowerCase(),
                              },
                              { merge: true }
                            )
                            .then(() => {
                              // assign360ToUser:
                              db.collection('accounts')
                                .doc(accountID)
                                .collection(`users`)
                                .doc(validUserKeys[validUserKey])
                                .collection(`360s`)
                                .doc(current360Id)
                                .collection(`group`)
                                .doc(newGroupId)
                                .collection(`questionnaires`)
                                .doc(questionnaireID)
                                .collection(`self-review`)
                                .doc(`status`)
                                .set({
                                  completed: false,
                                  completionPercentage: 0,
                                })
                                .then((data) => {
                                  db.collection('accounts')
                                    .doc(accountID)
                                    .collection(`users`)
                                    .doc(validUserKeys[validUserKey])
                                    .collection(`360s`)
                                    .doc(current360Id)
                                    .set(
                                      {
                                        groupID: newGroupId,
                                        questionnaireId: questionnaireID,
                                      },
                                      { merge: true }
                                    )
                                    .then((data) => {
                                      db.collection(`accounts`)
                                        .doc(accountID)
                                        .collection(`360s`)
                                        .doc(current360Id)
                                        .update(
                                          {
                                            userIDs: firebase.firestore.FieldValue.arrayUnion(validUserKeys[validUserKey]),
                                          },
                                          { merge: true }
                                        )
                                        .then(() => {
                                          if (i == validUserKeys.length - 1) {
                                            console.log('import reviewers!');
                                            importReviewersFromCSV(
                                              group,
                                              reviewerUsersToParse,
                                              validUserKeys,
                                              accountID,
                                              current360Id,
                                              newGroupId,
                                              questionnaireID,
                                              relationships
                                            ).then(() => {
                                              console.log('importReviewersFromCSV complete');
                                              resolve(newGroupId);
                                            });
                                          }
                                        });
                                    });
                                });
                            });
                        });
                      } else {
                        resolve(newGroupId);
                      }
                    });

                  // Object
                  //   .keys(group["users"])
                  //   .map((revieweeKey, i) => {
                  //     console.log("attempting to import user: "+revieweeKey);
                  //     // db.collection(`accounts`).doc(accountID).collection(`360s`).where("userIDs", "array-contains", revieweeKey).get().then((snapshot) => {
                  //     db.collection(`accounts`).doc(accountID).collection(`360s`).where("userIDs", "array-contains", revieweeKey).get().then((snapshot) => {
                  //       // console.log(snapshot);
                  //       if (!snapshot.empty) {
                  //         console.log("dont push "+ revieweeKey +" to group");
                  //       } else {
                  //         console.log("push "+ revieweeKey +" to group");
                  //         validUsers.push(revieweeKey);
                  //       }
                  //
                  //       if(i == Object.keys(group["users"]).length - 1) {
                  //         console.log(validUsers);
                  //       }
                  //     })
                  //
                  //   })
                });
              });
          }
        });
      });
    } else {
      // Create blank group with no users and return new groupID
      db.collection(`accounts`)
        .doc(accountID)
        .collection(`360s`)
        .doc(current360Id)
        .collection(`groups`)
        .add({
          title: group['title'],
          statusCode: group['statusCode'],
          activeTask: group['activeTask'],
          createdDate: group['createdDate'],
          locked: lockedReviewer,
        })
        .then((data) => {
          let newGroupId = data.id;

          // add all tasks:
          let batch = db.batch();
          Object.keys(group['tasks']).map((key) => {
            var docRef = db
              .collection(`accounts`)
              .doc(accountID)
              .collection(`360s`)
              .doc(current360Id)
              .collection(`groups`)
              .doc(newGroupId)
              .collection(`tasks`)
              .doc(key); //automatically generate unique id
            batch.set(docRef, {
              dueDate: group['tasks'][key].dueDate,
              completedDate: group['tasks'][key].completedDate,
              user: group['tasks'][key].user,
            });
          });

          batch.commit().then(function() {
            resolve(newGroupId);
          });
        });
    }
  });
};

export const doCreate360Team = (accountID, current360Id, title, users, questionnaireID, relationships, lockedReviewer) => {
  let oneWeekFromNow = new Date();
  oneWeekFromNow.setDate(oneWeekFromNow.getDate() + 7);

  let revieweeUsers = [];
  let reviewersUsers = [];

  let group = {};
  group['title'] = title;
  group['statusCode'] = 0;
  group['activeTask'] = 1;
  group['createdDate'] = Date.now();
  group['tasks'] = {
    '1': {
      dueDate: Date.parse(oneWeekFromNow),
      completedDate: false,
      user: false,
    },
    '2': {
      dueDate: false,
      completedDate: false,
      user: false,
    },
    '3': {
      dueDate: false,
      completedDate: false,
      user: false,
    },
    '4': {
      dueDate: false,
      completedDate: false,
      user: false,
    },
  };

  group['users'] = {};

  return new Promise(function(resolve, reject) {
    if (users.length > 1) {
      Object.keys(users).map((key) => {
        if (users[key].Relationship) {
          reviewersUsers.push(users[key]);
        }
      });

      let reviewerUsersToParse = reviewersUsers;

      db.collection(`accounts`)
        .doc(accountID)
        .collection(`360s`)
        .doc(current360Id)
        .collection(`groups`)
        .add({
          title: group['title'],
          statusCode: group['statusCode'],
          activeTask: group['activeTask'],
          createdDate: group['createdDate'],
          locked: lockedReviewer,
        })
        .then((data) => {
          let newGroupId = data.id;

          // add all tasks:
          let batch = db.batch();

          Object.keys(group['tasks']).map((key) => {
            var docRef = db
              .collection(`accounts`)
              .doc(accountID)
              .collection(`360s`)
              .doc(current360Id)
              .collection(`groups`)
              .doc(newGroupId)
              .collection(`tasks`)
              .doc(key); //automatically generate unique id
            batch.set(docRef, {
              dueDate: group['tasks'][key].dueDate,
              completedDate: group['tasks'][key].completedDate,
              user: group['tasks'][key].user,
            });
          });

          batch.commit().then(function() {
            doCreateTeamUser(accountID, users[0].name).then((newTeam) => {
              const teamID = Object.keys(newTeam)[0];
              group['users'][teamID] = users[0];
              addNewUserToTeam(accountID, newTeam, current360Id, newGroupId, questionnaireID);
              importReviewersFromCSV(group, reviewerUsersToParse, [teamID], accountID, current360Id, newGroupId, questionnaireID, relationships);
            });
            resolve(newGroupId);
          });
        });
    } else {
      revieweeUsers.push(users[0]);

      db.collection(`accounts`)
        .doc(accountID)
        .collection(`360s`)
        .doc(current360Id)
        .collection(`groups`)
        .add({
          title: group['title'],
          statusCode: group['statusCode'],
          activeTask: group['activeTask'],
          createdDate: group['createdDate'],
          locked: lockedReviewer,
        })
        .then((data) => {
          let newGroupId = data.id;

          // add all tasks:
          let batch = db.batch();

          Object.keys(group['tasks']).map((key) => {
            var docRef = db
              .collection(`accounts`)
              .doc(accountID)
              .collection(`360s`)
              .doc(current360Id)
              .collection(`groups`)
              .doc(newGroupId)
              .collection(`tasks`)
              .doc(key); //automatically generate unique id
            batch.set(docRef, {
              dueDate: group['tasks'][key].dueDate,
              completedDate: group['tasks'][key].completedDate,
              user: group['tasks'][key].user,
            });
          });

          batch.commit().then(function() {
            doCreateTeamUser(accountID, users[0].name).then((newTeam) => {
              const teamID = Object.keys(newTeam)[0];
              addNewUserToTeam(accountID, newTeam, current360Id, newGroupId, questionnaireID);
            });
          });
          resolve(newGroupId);
        });
    }
  });
};

export const addNewUserToGroup = (accountID, userData, current360Id, currentGroupId, currentQuestionnaireId) => {
  let newUser = {};
  let newUserID;

  Object.keys(userData).map((key) => {
    newUserID = key;
    let groupUserData = {
      email: userData[key]['email'],
      forename: userData[key]['forename'],
      surname: userData[key]['surname'],
    };

    newUser[key] = groupUserData;
  });

  return new Promise(function(resolve, reject) {
    db.collection(`accounts`)
      .doc(accountID)
      .collection(`360s`)
      .doc(current360Id)
      .collection(`groups`)
      .doc(currentGroupId)
      .collection(`users`)
      .doc(newUserID)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          reject('User already exists in this Group!');
        } else {
          // db.collection(`accounts`).doc(accountID).collection(`360s`).where("userIDs", "array-contains", newUserID).get().then((snapshot) => {
          db.collection(`accounts`)
            .doc(accountID)
            .collection(`360s`)
            .doc(current360Id)
            .get()
            .then((snapshot) => {
              let userIDs = [];

              if (snapshot.data()['userIDs']) {
                userIDs = snapshot.data()['userIDs'];
              }

              if (userIDs.includes(newUserID)) {
                reject('User already exists in another group!');
              } else {
                // Push user to Group
                db.collection('accounts')
                  .doc(accountID)
                  .collection('360s')
                  .doc(current360Id)
                  .collection('groups')
                  .doc(currentGroupId)
                  .collection('users')
                  .doc(newUserID)
                  .set(
                    {
                      email: newUser[newUserID]['email'].replace(/\s+/g, '').toLowerCase(),
                      forename: newUser[newUserID]['forename'],
                      surname: newUser[newUserID]['surname'],
                    },
                    { merge: true }
                  )
                  .then(() => {
                    // assign360ToUser:

                    db.collection('accounts')
                      .doc(accountID)
                      .collection(`users`)
                      .doc(newUserID)
                      .collection(`360s`)
                      .doc(current360Id)
                      .collection(`group`)
                      .doc(currentGroupId)
                      .collection(`questionnaires`)
                      .doc(currentQuestionnaireId)
                      .collection(`self-review`)
                      .doc(`status`)
                      .set({
                        completed: false,
                        completionPercentage: 0,
                      })
                      .then((data) => {
                        db.collection('accounts')
                          .doc(accountID)
                          .collection(`users`)
                          .doc(newUserID)
                          .collection(`360s`)
                          .doc(current360Id)
                          .set(
                            {
                              groupID: currentGroupId,
                              questionnaireId: currentQuestionnaireId,
                            },
                            { merge: true }
                          )
                          .then((data) => {
                            db.collection(`accounts`)
                              .doc(accountID)
                              .collection(`360s`)
                              .doc(current360Id)
                              .update(
                                {
                                  userIDs: firebase.firestore.FieldValue.arrayUnion(newUserID),
                                },
                                { merge: true }
                              )
                              .then(() => {
                                resolve();
                              });
                          });
                      });
                  });
              }
            });
        }
      });
  });

  // return db.ref(`360/${current360Id}/groups/${currentGroupId}/`).child(`users`).update(
  //   newUser
  // )

  // return db.ref(`360/${current360Id}/groups/${currentGroupId}/users/`).push(
  //   userData
  // )
};

export const addNewUserToTeam = (accountID, userData, current360Id, currentGroupId, currentQuestionnaireId) => {
  let newUser = { ...userData };
  let newUserID = Object.keys(userData)[0];

  return new Promise(function(resolve, reject) {
    db.collection('accounts')
      .doc(accountID)
      .collection('360s')
      .doc(current360Id)
      .collection('groups')
      .doc(currentGroupId)
      .collection('users')
      .doc(newUserID)
      .set(
        {
          name: newUser[newUserID]['name'],
          forename: newUser[newUserID]['forename'],
          surname: newUser[newUserID]['surname'],
          email: newUser[newUserID]['email'],
        },
        { merge: true }
      )
      .then(() => {
        db.collection('accounts')
          .doc(accountID)
          .collection(`users`)
          .doc(newUserID)
          .collection(`360s`)
          .doc(current360Id)
          .collection(`group`)
          .doc(currentGroupId)
          .collection(`questionnaires`)
          .doc(currentQuestionnaireId)
          .collection(`self-review`)
          .doc(`status`)
          .set({
            completed: true,
            completionPercentage: 100,
          })
          .then(() => {
            db.collection('accounts')
              .doc(accountID)
              .collection(`users`)
              .doc(newUserID)
              .collection(`360s`)
              .doc(current360Id)
              .set(
                {
                  groupID: currentGroupId,
                  questionnaireId: currentQuestionnaireId,
                },
                { merge: true }
              )
              .then(() => {
                db.collection(`accounts`)
                  .doc(accountID)
                  .collection(`360s`)
                  .doc(current360Id)
                  .update(
                    {
                      userIDs: firebase.firestore.FieldValue.arrayUnion(newUserID),
                    },
                    { merge: true }
                  )
                  .then(() => {
                    resolve();
                  });
              });
          });
      });
  });
};

export const assign360ToUser = (accountID, userID, current360Id, currentGroupId) => {
  return doGet360QuestionniareID(accountID, current360Id).then((data) => {
    let questionnaireID = data.val();

    let blankQuestionnaireObj = {
      completed: false,
      completionPercentage: 0,
      sections: false,
    };

    // let obj[questionnaireID] = blankQuestionnaireObj

    return db
      .ref(`accounts/${accountID}/users/${userID}/360s/${current360Id}/group/${currentGroupId}/questionnaires/${questionnaireID}/self-review/`)
      .set(blankQuestionnaireObj);
  });
};

export const doCreateNewQuestion = (accountID, questionnaireID, sectionID, questionData) => {
  // use "set" method after creating a new document with unix timestamp (Date.now())
  return db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`questionnaires`)
    .doc(questionnaireID)
    .collection('sections')
    .doc(sectionID)
    .collection('questions')
    .doc(Date.now().toString())
    .set({
      ...questionData,
      id: Date.now().toString(),
    });
};

export const updateQuestion = (accountID, questionnaireID, sectionID, questionID, questionData) => {
  return db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`questionnaires`)
    .doc(questionnaireID)
    .collection('sections')
    .doc(sectionID)
    .collection('questions')
    .doc(questionID)
    .set(
      {
        ...questionData,
      },
      { merge: true }
    );
};

export const updateGroupTaskCompleted = (accountID, current360ID, groupID, taskNumber, userEmail) => {
  db.collection(`accounts`)
    .doc(accountID)
    .collection(`360s`)
    .doc(current360ID)
    .collection(`groups`)
    .doc(groupID)
    .collection(`tasks`)
    .doc(taskNumber.toString())
    .set(
      {
        completedDate: Date.now(),
        user: userEmail,
      },
      { merge: true }
    );
};

export const updateGroupActiveTask = (accountID, current360ID, groupID, reminderNumber) => {
  db.collection(`accounts`)
    .doc(accountID)
    .collection(`360s`)
    .doc(current360ID)
    .collection(`groups`)
    .doc(groupID)
    .set(
      {
        activeTask: reminderNumber,
      },
      { merge: true }
    );
};

export const updateCreditsAvailable = (accountID, current360ID, newCreditsAvailable) => {
  db.collection(`accounts`)
    .doc(accountID)
    .set(
      {
        credits: newCreditsAvailable,
      },
      { merge: true }
    );
};
export const updateGroupDeadlineDate = (accountID, current360ID, groupID, deadlineDate) => {
  db.collection(`accounts`)
    .doc(accountID)
    .collection(`360s`)
    .doc(current360ID)
    .collection(`groups`)
    .doc(groupID)
    .set(
      {
        deadlineDate: Date.parse(deadlineDate),
      },
      { merge: true }
    );
};

export const updateNextTaskDueDate = (accountID, current360ID, groupID, reminderNumber) => {
  let oneWeekFromNow = new Date();
  oneWeekFromNow.setDate(oneWeekFromNow.getDate() + 7);

  db.collection(`accounts`)
    .doc(accountID)
    .collection(`360s`)
    .doc(current360ID)
    .collection(`groups`)
    .doc(groupID)
    .collection(`tasks`)
    .doc(reminderNumber.toString())
    .set(
      {
        dueDate: Date.parse(oneWeekFromNow),
      },
      { merge: true }
    );
};

export const update360StatusCode = (accountID, current360Id, statusCode) => {
  return db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`360s`)
    .doc(current360Id)
    .set(
      {
        statusCode: statusCode,
      },
      { merge: true }
    );
};

export const doCreateNewQuestionnaireSection = (accountID, questionnaireRef, sectionTitle, sectionDescription, sectionsCount, projectType) => {
  return new Promise(function(resolve, reject) {
    //creates a timestamp for section ID naming. The timestamps are also used for ordering in sections legacy mode
    let i = new Date().getTime();
    if (projectType !== 'Goal Tracker') {
      //creates gets the current datetime so that it saves section in date order

      db.collection(`accounts`)
        .doc(accountID)
        .collection(`questionnaires`)
        .doc(questionnaireRef)
        .collection('sections')
        .doc(`${i}`)
        .set(
          {
            title: sectionTitle,
            description: sectionDescription,
            created: firebase.firestore.FieldValue.serverTimestamp(),
            sortOrder: sectionsCount,
          },
          { merge: false }
        )
        .then((snapshot) => {
          resolve();
        });
    } else {
      let questionsObj = [
        {
          answerType: 'FreeText',
          questionTitle: 'My goal is',
          required: true,
          sortOrder: 0,
          id: '1632815630062',
        },
        {
          answerType: 'FreeText',
          questionTitle: 'Specific actions needed to deliver my goal',
          required: true,
          sortOrder: 1,
          id: '1632815640162',
        },
        {
          answerType: 'FreeText',
          questionTitle: 'I will measure my success by',
          required: true,
          sortOrder: 2,
          id: '1632815650262',
        },
        {
          answerType: 'FreeText',
          questionTitle: 'The support I need',
          required: true,
          sortOrder: 3,
          id: '1632815660362',
        },
        {
          answerType: 'Date',
          questionTitle: 'When will I review my progress',
          required: true,
          sortOrder: 4,
          id: '1632815670462',
        },
        {
          answerType: 'FreeText',
          questionTitle: 'My progress to date',
          required: true,
          sortOrder: 5,
          id: '1632815680562',
        },
        {
          answerType: 'Date',
          questionTitle: 'My deadline is',
          required: true,
          sortOrder: 6,
          id: '1632815690662',
        },
      ];

      let questionNum = 0;

      db.collection(`accounts`)
        .doc(accountID)
        .collection(`questionnaires`)
        .doc(questionnaireRef)
        .collection('sections')
        .doc(`${i}`)
        .set(
          {
            title: sectionTitle,
            description: sectionDescription,
            created: firebase.firestore.FieldValue.serverTimestamp(),
            sortOrder: sectionsCount,
          },
          { merge: false }
        )
        .then((snapshot) => {
          let questionnaireSection = i;
          Object.keys(questionsObj).map((question, i) => {
            // db.collection(`accounts`).doc(accountID).collection(`questionnaires`).doc(questionnaireRef).collection(`sections`).doc(sectionKey).collection(`questions`).add({
            setTimeout(() => {
              db.collection(`accounts`)
                .doc(accountID)
                .collection(`questionnaires`)
                .doc(questionnaireRef)
                .collection(`sections`)
                .doc(`${questionnaireSection}`)
                .collection(`questions`)
                .doc(`${questionsObj[question]['id']}`)
                .set(
                  {
                    answerType: questionsObj[question]['answerType'],
                    questionTitle: questionsObj[question]['questionTitle'],
                    required: questionsObj[question]['required'],
                    id: questionsObj[question]['id'],
                    sortOrder: questionsObj[question]['sortOrder'],
                  },
                  { merge: false }
                )
                .then((snapshot) => {
                  questionNum++;

                  if (questionNum == Object.keys(questionsObj).length) {
                    resolve();
                  }
                });
            }, i * 250);
          });
        });
    }
  });
};

export const doDuplicateQuestionnaireSection = (accountID, questionnaireID, sectionToCopy, sectionTitle, sectionDescription, sectionsCount) => {
  const time = Date.now();
  return new Promise(function(resolve, reject) {
    db.collection(`accounts`)
      .doc(accountID)
      .collection(`questionnaires`)
      .doc(questionnaireID)
      .collection(`sections`)
      .doc(sectionToCopy)
      .get()
      .then((snapshot) => {
        let section = snapshot.data();
        db.collection(`accounts`)
          .doc(accountID)
          .collection(`questionnaires`)
          .doc(questionnaireID)
          .collection(`sections`)
          .doc(sectionToCopy)
          .collection(`questions`)
          .onSnapshot((snapshot) => {
            let questions = {};
            snapshot.docs.map((doc) => {
              // uses timestamp and duplicated question id to calculate new unique id value instead of just a duplicate one, assigns copied question value
              questions[+doc.id + +time] = doc.data();
            });

            // Takes the original id value stored in the properties for each question, assigns it to a new property (copyID)
            // Replaces previous ID value with the newly calculated ID value
            Object.keys(questions).forEach((qID) => {
              questions[qID]['copyID'] = questions[qID].id;
              questions[qID].id = qID;
            });

            //gets the current datetime so that the new section is added and displays in order
            //this is also used resolving the promise and duplicating the questions
            let i = new Date().getTime();

            //creates the new section using the 'i' variable as it's name
            db.collection('accounts')
              .doc(accountID)
              .collection('questionnaires')
              .doc(questionnaireID)
              .collection('sections')
              .doc(`${i}`)
              .set({
                title: sectionTitle,
                description: sectionDescription,
                sortOrder: sectionsCount,
                created: firebase.firestore.FieldValue.serverTimestamp(),
                sortOrder: sectionsCount,
              })
              .then((snapshot) => {
                if (Object.keys(questions).length) {
                  let batch = db.batch();
                  Object.keys(questions).map((key) => {
                    //creates the doc & uses the original doc ID as the new doc ID
                    var docRef = db
                      .collection('accounts')
                      .doc(accountID)
                      .collection('questionnaires')
                      .doc(questionnaireID)
                      .collection('sections')
                      .doc(`${i}`)
                      .collection(`questions`)
                      .doc(key); //create a new question with the same key as the copied one
                    batch.set(docRef, questions[key]);
                  });

                  batch
                    .commit()
                    .then(function() {
                      resolve(`${i}`);
                    })
                    .catch((err) => {
                      console.log(err);
                      resolve(err);
                    });
                } else {
                  resolve(`${i}`);
                }
              });
          });
      });

    // db.ref(`accounts/${accountID}/questionnaires/${questionnaireID}/sections/${sectionToCopy}`).once("value", snapshot => {
    //   if (snapshot.exists()) {
    //     let questionsToCopy = snapshot.val().questions
    //     db.ref(`accounts/${accountID}/questionnaires/${questionnaireID}/sections`).push(
    //       newSectionObj
    //     ).then((data) => {
    //       let newSectionKey = data.key
    //       if(questionsToCopy) {
    //         Object
    //           .keys(questionsToCopy)
    //           .map(key => {
    //             db.ref(`accounts/${accountID}/questionnaires/${questionnaireID}/sections/${newSectionKey}/questions/`).push(questionsToCopy[key])
    //           })
    //       }
    //
    //       resolve()
    //     })
    //   } else {
    //     reject("cannot copy section")
    //   }
    // })
  });

  // return db.ref(`questionnaires/${questionnaireRef}/sections`).push(
  //   newSectionTitle
  // )
};

export const doAssignQuestionnaireTo360 = (accountID, questionnaireRef, current360Id) => {
  // Bind questionnaire to current 360
  return db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`360s`)
    .doc(current360Id)
    .set(
      {
        questionnaireID: questionnaireRef,
      },
      { merge: true }
    );
};

export const doPopulateQuick360Questionnaire = (accountID, questionnaireID, current360Id) => {
  console.log('doPopulateQuick360Questionnaire');
  console.log(accountID, questionnaireID, current360Id);
  let quickThreeSixtyObject = [
    {
      title: 'Leads and Inspires',
      description: '',
      questions: [
        {
          answerType: 'MultiChoice',
          questionTitle: 'Believes and talks passionately in the future of the organisation',
          required: true,
          sortOrder: 0,
          id: '1632815630662',
        },
        {
          answerType: 'MultiChoice',
          questionTitle: 'Communicates in an honest, confident and constructive manner',
          required: true,
          sortOrder: 1,
          id: '1632815630762',
        },
        {
          answerType: 'MultiChoice',
          questionTitle: 'Explains how others can contribute to the achievement of the wider organisational goals',
          required: true,
          sortOrder: 2,
          id: '1632815630862',
        },
        {
          answerType: 'MultiChoice',
          questionTitle: 'Empowers others to take ownership',
          required: true,
          sortOrder: 3,
          id: '1632815630962',
        },
        {
          answerType: 'MultiChoice',
          questionTitle: 'Embraces and champions change',
          required: true,
          sortOrder: 4,
          id: '1632815631162',
        },
      ],
    },
    {
      title: 'Leads and Innovates',
      description: '',
      questions: [
        {
          answerType: 'MultiChoice',
          questionTitle: 'Generates alternative approaches to traditional ways of working',
          required: true,
          sortOrder: 0,
          id: '1632815730670',
        },
        {
          answerType: 'MultiChoice',
          questionTitle: 'Challenges in order to find and solve the root cause of problems',
          required: true,
          sortOrder: 1,
          id: '1632815730671',
        },
        {
          answerType: 'MultiChoice',
          questionTitle: 'Includes others in the problem-solving process',
          required: true,
          sortOrder: 2,
          id: '1632815730672',
        },
        {
          answerType: 'MultiChoice',
          questionTitle: 'Encourages creativity and feedback in order to achieve the best possible results',
          required: true,
          sortOrder: 3,
          id: '1632815730673',
        },
        {
          answerType: 'MultiChoice',
          questionTitle: 'Seeks feedback from a variety of people in order to learn and develop',
          required: true,
          sortOrder: 4,
          id: '1632815730674',
        },
      ],
    },
    {
      title: 'Leads and Develops',
      description: '',
      questions: [
        {
          answerType: 'MultiChoice',
          questionTitle: 'Invests time on a one to one basis coaching and developing others',
          required: true,
          sortOrder: 0,
          id: '1632815730680',
        },
        {
          answerType: 'MultiChoice',
          questionTitle: 'Spends time understanding individuals needs and requirements',
          required: true,
          sortOrder: 1,
          id: '1632815730681',
        },
        {
          answerType: 'MultiChoice',
          questionTitle: 'Understands that people learn and think in different ways and therefore adapts style to suit the individual',
          required: true,
          sortOrder: 2,
          id: '1632815730682',
        },
        {
          answerType: 'MultiChoice',
          questionTitle: 'Supports others in the personal development',
          required: true,
          sortOrder: 3,
          id: '1632815730683',
        },
        {
          answerType: 'MultiChoice',
          questionTitle: 'Values continual professional development for self and others',
          required: true,
          sortOrder: 4,
          id: '1632815730684',
        },
      ],
    },
    {
      title: 'Manages Performance',
      description: '',
      questions: [
        {
          answerType: 'MultiChoice',
          questionTitle: 'Sets meaningful targets/objectives for people to achieve',
          required: true,
          sortOrder: 0,
          id: '1632815730690',
        },
        {
          answerType: 'MultiChoice',
          questionTitle: 'Agrees measures and outcomes with individuals',
          required: true,
          sortOrder: 1,
          id: '1632815730691',
        },
        {
          answerType: 'MultiChoice',
          questionTitle: 'Explain the consequences of performance – if this be good, bad or indifferent',
          required: true,
          sortOrder: 2,
          id: '1632815730692',
        },
        {
          answerType: 'MultiChoice',
          questionTitle: 'Gives feedback and praise for a job well done',
          required: true,
          sortOrder: 3,
          id: '1632815730693',
        },
        {
          answerType: 'MultiChoice',
          questionTitle: 'Has the difficult conversations when required?',
          required: true,
          sortOrder: 4,
          id: '1632815730694',
        },
      ],
    },
  ];

  return new Promise(function(resolve, reject) {
    setTimeout(
      Object.keys(quickThreeSixtyObject).map((sectionKey, sectionCount) => {
        console.log(quickThreeSixtyObject[sectionKey]);
        //adding the section count to the date as the map function can run fast enough that multiple sections are created within the same MS
        let docKey = Date.now() + sectionCount;

        console.log(Date.now());
        db.collection(`accounts`)
          .doc(accountID)
          .collection(`questionnaires`)
          .doc(questionnaireID)
          .collection(`sections`)
          .doc(`${docKey}`)
          .set(
            {
              title: quickThreeSixtyObject[sectionKey]['title'],
              description: quickThreeSixtyObject[sectionKey]['description'],
              created: firebase.firestore.FieldValue.serverTimestamp(),
              sortOrder: sectionCount,
            },
            { merge: false }
          )
          .then((snapshot) => {
            Object.keys(quickThreeSixtyObject[sectionKey]['questions']).map((questionKey, questionCount) => {
              let questKey = Date.now() + questionCount;
              db.collection(`accounts`)
                .doc(accountID)
                .collection(`questionnaires`)
                .doc(questionnaireID)
                .collection(`sections`)
                .doc(`${docKey}`)
                .collection(`questions`)
                .doc(`${quickThreeSixtyObject[sectionKey]['questions'][questionKey]['id']}`)
                .set(
                  {
                    answerType: quickThreeSixtyObject[sectionKey]['questions'][questionKey]['answerType'],
                    questionTitle: quickThreeSixtyObject[sectionKey]['questions'][questionKey]['questionTitle'],
                    required: quickThreeSixtyObject[sectionKey]['questions'][questionKey]['required'],
                    id: quickThreeSixtyObject[sectionKey]['questions'][questionKey]['id'],
                    sortOrder: quickThreeSixtyObject[sectionKey]['questions'][questionKey]['sortOrder'],
                  },
                  { merge: false }
                )
                .then((snapshot) => {
                  if (
                    sectionCount == Object.keys(quickThreeSixtyObject).length &&
                    questionCount == Object.keys(quickThreeSixtyObject[sectionKey]['questions']).length
                  ) {
                    resolve();
                  }
                });
            });
          });
        // db.collection(`accounts`).doc(accountID).collection(`questionnaires`).doc(questionnaireID).collection(`sections`).add({
        //   answerType: questionnaireSectionAnswers[questionnaireSectionAnswerId]['answerType'],
        //   questionTitle: questionnaireSectionAnswers[questionnaireSectionAnswerId]['questionTitle'],
        //   required: questionnaireSectionAnswers[questionnaireSectionAnswerId]['required']
        // }, { merge: true }).then((data) => {
        // })
      }),
      10000
    );

    resolve();
  });
};

export const copyCompetenciesTo360 = (accountID, questionnaireIdToCopy, questionnaireIdDestination, sectionsCount) => {
  return new Promise(function(resolve, reject) {
    console.log(questionnaireIdDestination);
    db.collection(`accounts`)
      .doc(accountID)
      .collection(`questionnaires`)
      .doc(questionnaireIdToCopy)
      .collection(`sections`)
      // .orderBy('created')
      .get()
      .then((snapshot) => {
        if (!snapshot.empty) {
          let questionnaireSections = {};
          // returns false if any sections don't have a sortOrder field
          const sectionsSortOrderCheck = !snapshot.docs.map((doc) => doc.data().sortOrder).includes(undefined);

          snapshot.docs
            .sort((a, b) => {
              if (sectionsSortOrderCheck) {
                return a.data().sortOrder - b.data().sortOrder;
              } else {
                return a.id - b.id;
              }
            })
            .map((doc, i) => {
              let questionnaireSectionId = doc.id;
              questionnaireSections[questionnaireSectionId] = doc.data();
              //this gets the current dateTime and adds it to window.performance.now() which uses microseconds and then rounds up to generate a new ID
              //this allows for a unique ID to be applied when copying sections from the same place multiple times without having conflicting IDs
              // just to be safe, it also adds the index value to the figure, to ensure no id conflicts
              let newQuestionnaireSecId = Math.ceil(new Date().getTime() + window.performance.now() * 100) + i;

              // checks if new section has a sortOrder property, then adds the sectionsCount value to either the sortOrder or index
              // resets the new section sortOrder property with the newly calculated value, to order after any existing sections
              const sortOrder = questionnaireSections[questionnaireSectionId].sortOrder;
              const docSortOrder = sortOrder !== undefined ? sortOrder : i;
              questionnaireSections[questionnaireSectionId].sortOrder = docSortOrder + sectionsCount;

              db.collection(`accounts`)
                .doc(accountID)
                .collection(`questionnaires`)
                .doc(questionnaireIdDestination)
                .collection('sections')
                .doc(`${newQuestionnaireSecId}`)
                .set(
                  {
                    title: questionnaireSections[questionnaireSectionId]['title'],
                    description: questionnaireSections[questionnaireSectionId]['description'],
                    created: firebase.firestore.FieldValue.serverTimestamp(),
                    sortOrder: questionnaireSections[questionnaireSectionId].sortOrder,
                  },
                  { merge: true }
                )
                .then((data) => {
                  db.collection(`accounts`)
                    .doc(accountID)
                    .collection(`questionnaires`)
                    .doc(questionnaireIdToCopy)
                    .collection('sections')
                    .doc(questionnaireSectionId)
                    .collection(`questions`)
                    .get()
                    .then((snapshot) => {
                      if (!snapshot.empty) {
                        let questionnaireSectionAnswers = {};
                        snapshot.docs.map((doc, i) => {
                          let questionnaireSectionAnswerId = doc.id;
                          questionnaireSectionAnswers[questionnaireSectionAnswerId] = doc.data();

                          db.collection(`accounts`)
                            .doc(accountID)
                            .collection(`questionnaires`)
                            .doc(questionnaireIdDestination)
                            .collection('sections')
                            .doc(`${newQuestionnaireSecId}`)
                            .collection(`questions`)
                            .doc(questionnaireSectionAnswerId)
                            .set(
                              {
                                answerType: questionnaireSectionAnswers[questionnaireSectionAnswerId]['answerType'],
                                questionTitle: questionnaireSectionAnswers[questionnaireSectionAnswerId]['questionTitle'],
                                required: questionnaireSectionAnswers[questionnaireSectionAnswerId]['required'],
                                id: questionnaireSectionAnswers[questionnaireSectionAnswerId]['id'],
                                sortOrder: questionnaireSectionAnswers[questionnaireSectionAnswerId]['sortOrder'],
                              },
                              { merge: true }
                            )
                            .then((data) => {});
                        });
                      } else {
                        resolve();
                      }
                    });
                });
            });
        } else {
          resolve();
        }
      });

    // db.ref(`accounts/${accountID}/questionnaires/${questionnaireIdToCopy}`).once("value", snapshot => {
    //   if (snapshot.exists()) {
    //     db.ref(`accounts/${accountID}/questionnaires/${questionnaireIdDestination}`).set(
    //       snapshot.val()
    //     ).then(() => {
    //       resolve()
    //     })
    //   } else {
    //     reject("no questionnaire setup for this 360")
    //   }
    // })
  });
};

export const onceGetUsers = () => db.ref('users').once('value');

export const doGet360s = (accountID) =>
  db
    .collection(`accounts`)
    .doc(accountID)
    .collection('360s')
    .orderBy('title', 'desc')
    .get();

export const doGetCurrent360 = (accountID, current360ID) =>
  db
    .collection(`accounts`)
    .doc(accountID)
    .collection('360s')
    .doc(current360ID)
    .get();

export const doGetClients = (accountID) =>
  db
    .collection(`accounts`)
    .doc(accountID)
    .collection('clients')
    .get();

export const doGet360QuestionniareID = (accountID, current360ID) =>
  db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`360s`)
    .doc(current360ID)
    .get();

// db.ref(`accounts/${accountID}/360s/${current360ID}/questionnaireID`).once('value')

// export const doGetCurrent360 = (current360ID) =>
//   db.ref(`360/${current360ID}/`).once('value')

export const doDeleteGroup = (accountID, current360ID, groupID, users) => {
  // updating an object to null will delete them! (rather than doing 1-at-a-time)
  let usersToDelete = {};

  return new Promise(function(resolve, reject) {
    Object.keys(users).map((key) => {
      db.collection(`accounts`)
        .doc(accountID)
        .collection(`360s`)
        .doc(current360ID)
        .update({
          userIDs: firebase.firestore.FieldValue.arrayRemove(key),
        });
    });

    db.collection(`accounts`)
      .doc(accountID)
      .collection(`360s`)
      .doc(current360ID)
      .collection(`groups`)
      .doc(groupID)
      .delete()
      .then(() => {
        resolve(true);
      });
  });
  // db.ref(`accounts/${accountID}/360s/${current360Id}/userIDs/${newUserID}/`).once("value", snapshot => {
};

export const doDeleteQuestion = (accountID, questionnaireID, sectionID, questionID) =>
  db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`questionnaires`)
    .doc(questionnaireID)
    .collection(`sections`)
    .doc(sectionID)
    .collection(`questions`)
    .doc(questionID)
    .delete();

export const doDeleteSection = (accountID, questionnaireID, sectionID) =>
  db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`questionnaires`)
    .doc(questionnaireID)
    .collection(`sections`)
    .doc(sectionID)
    .delete();

// export const doDuplicateSection = (questionnaireID, sectionID) => {
//   return new Promise(function(resolve, reject) {
//     db.ref(`questionnaires/${questionnaireID}/sections/${sectionID}`).once("value", snapshot => {
//       if (snapshot.exists()) {
//         console.log(snapshot.val().questions)
//         // db.ref(`questionnaires/${questionnaireID}/sections`).push(
//         //   snapshot.val()
//         // ).then(() => {
//         //   resolve()
//         // })
//       } else {
//         reject("cannot copy section")
//       }
//     })
//   })
// }

export const doDeleteReviewerFromReviewee = (accountID, reviewerID, current360ID, groupID, userID) => {
  return new Promise(function(resolve, reject) {
    db.collection(`accounts`)
      .doc(accountID)
      .collection(`360s`)
      .doc(current360ID)
      .collection(`groups`)
      .doc(groupID)
      .collection(`users`)
      .doc(userID)
      .collection(`reviewers`)
      .doc(reviewerID)
      .get()
      .then((snapshot) => {
        snapshot.ref.delete();
        resolve();
      });
  });
  // return db.collection(`accounts`).doc(accountID).
  //   collection(`360s`).doc(current360ID).
  //     collection(`groups`).doc(groupID).
  //       collection(`users`).doc(userID).
  //         collection(`reviewers`).doc(reviewerID).delete().then((data) => {
  //
  //           db.collection(`accounts`).doc(accountID).
  //             collection(`360s`).doc(current360ID).
  //               collection(`groups`).doc(groupID).
  //                 collection(`users`).doc(userID).
  //                   collection(`reviewers`).doc(reviewerID).
  //                     collection(`reviewer`).doc(`status`).delete()
  //
  //         })
};

// db.ref(`accounts/${accountID}/360s/${current360ID}/groups/${groupID}/users/${userID}/reviewers/`).child(reviewerID).remove()

export const doDeleteReviewer = (accountID, reviewerID) =>
  db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`reviewers`)
    .doc(reviewerID)
    .delete();

export const doDelete360 = (accountId, current360Id) =>
  db
    .collection(`accounts`)
    .doc(accountId)
    .collection(`360s`)
    .doc(current360Id)
    .delete();

export const doArchive360 = (accountId, current360Id) => {
  return db
    .collection(`accounts`)
    .doc(accountId)
    .collection(`360s`)
    .doc(current360Id)
    .set(
      {
        archived: true,
      },
      { merge: true }
    );
};

export const doUnarchive360 = (accountId, current360Id) => {
  return db
    .collection(`accounts`)
    .doc(accountId)
    .collection(`360s`)
    .doc(current360Id)
    .set(
      {
        archived: false,
      },
      { merge: true }
    );
};

export const doGetCurrent360Relationships = (accountID, current360ID) =>
  db
    .collection(`accounts`)
    .doc(accountID)
    .collection('360s')
    .doc(current360ID)
    .collection(`relationships`)
    .get();

export const doGetCurrent360Groups = (accountID, current360ID) =>
  db
    .collection(`accounts`)
    .doc(accountID)
    .collection('360s')
    .doc(current360ID)
    .collection(`groups`)
    .get();

export const doGetSectionInfo = (accountID, questionnaireID, sectionID) =>
  db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`questionnaires`)
    .doc(questionnaireID)
    .collection(`sections`)
    .doc(sectionID)
    .get();

export const doGetSectionQuestions = (accountID, questionnaireID, sectionID) =>
  db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`questionnaires`)
    .doc(questionnaireID)
    .collection(`sections`)
    .doc(sectionID)
    .collection(`questions`);

export const doGetUserGroups = (accountID, current360ID) =>
  db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`360s`)
    .doc(current360ID)
    .collection(`groups`);

export const doGetClientLogo = (accountID, current360ID) => {
  return new Promise(function(resolve, reject) {
    // db.collection(`accounts`).doc(accountID).collection(`360s`).doc(current360ID).collection(`groups`)
    db.ref(`accounts/${accountID}/360s/${current360ID}/brand/logo`).once('value', (snapshot) => {
      if (snapshot.val()) {
        resolve(snapshot.val());
      } else {
        db.ref(`accounts/${accountID}/settings/logo`).once('value', (snapshot) => {
          if (snapshot.val()) {
            resolve(snapshot.val());
          } else {
            reject('no logo found');
          }
        });
      }
    });
  });
};

export const moveUserToGroup = (accountID, current360Id, userID, groupID, newGroupID) => {
  return new Promise(function(resolve, reject) {
    db.collection(`accounts`)
      .doc(accountID)
      .collection(`360s`)
      .doc(current360Id)
      .collection('groups')
      .doc(groupID)
      .collection('users')
      .doc(userID)
      .get()
      .then((snapshot) => {
        let userToMove = snapshot.data();
        let newUserId = snapshot.id;

        if (userToMove && newUserId) {
          db.collection(`accounts`)
            .doc(accountID)
            .collection(`360s`)
            .doc(current360Id)
            .collection('groups')
            .doc(newGroupID)
            .collection('users')
            .doc(newUserId)
            .set(userToMove)
            .then((snapshot) => {
              db.collection(`accounts`)
                .doc(accountID)
                .collection(`360s`)
                .doc(current360Id)
                .collection('groups')
                .doc(groupID)
                .collection('users')
                .doc(userID)
                .delete()
                .then(() => {
                  resolve(true);
                });
            });
        } else {
          reject('no logo found');
        }
      });

    // db.ref(`accounts/${accountID}/360s/${current360Id}/groups/${ groupID }/users/${ userID }/`).once("value", snapshot => {
    //   let userToMove = snapshot.val()
    //
    //   if(userToMove) {
    //
    //     db.ref(`accounts/${accountID}/360s/${current360Id}/groups/${newGroupID}/users/`).child(snapshot.key).update(
    //       userToMove, () => {
    //
    //         db.ref(`accounts/${accountID}/360s/${current360Id}/groups/${ groupID }/users/`).child(userID).remove();
    //
    //         resolve()
    //       }
    //     )
    //
    //
    //   } else {
    //     reject("no logo found")
    //   }
    // })
  });
  console.log(`movingUser ${userID} From ${groupID} To Group ${newGroupID}`);
};

export const doGetSectionsAndQuestionDetailsList = (accountID, questionnaireID) => {
  return new Promise(function(resolve, reject) {
    let count = 0;
    let sectionsAndQuestions = {};
    console.log('doGetSectionsAndQuestionDetailsList');
    console.log(accountID, questionnaireID);
    db.collection(`accounts`)
      .doc(accountID)
      .collection(`questionnaires`)
      .doc(questionnaireID)
      .collection('sections')
      .onSnapshot((snapshot) => {
        let numSections = snapshot.docs.length;
        console.log('numSections');
        console.log(numSections);
        snapshot.docs.map((doc, i) => {
          let sectionID = doc.id;

          if (doc.data()['title']) {
            sectionsAndQuestions[sectionID] = doc.data();
          }

          db.collection(`accounts`)
            .doc(accountID)
            .collection(`questionnaires`)
            .doc(questionnaireID)
            .collection('sections')
            .doc(sectionID)
            .collection(`questions`)
            .onSnapshot((snapshot) => {
              count++;

              if (sectionsAndQuestions[sectionID]) {
                sectionsAndQuestions[sectionID]['questions'] = {};
                snapshot.docs.map((doc, i) => {
                  sectionsAndQuestions[sectionID]['questions'][doc.id] = doc.data();
                });
              }

              if (count == numSections) {
                resolve(sectionsAndQuestions);
              }
            });
        });
      });
  });
};

export const doDeleteTempUserAndCreateUserWithEmailAndPassword = (email, password) => {
  return new Promise(function(resolve, reject) {
    auth.currentUser.delete().then(function() {
      auth.createUserWithEmailAndPassword(email, password).then(function(authUser) {
        resolve(authUser);
      });
    });
  });
};

export const doCreateUserWithEmailAndPassword = (email, password) => auth.createUserWithEmailAndPassword(email, password);

export const doGetAnswers = (accountID, userID, current360ID, groupID, questionnaireID, reviewType) =>
  db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`users`)
    .doc(userID)
    .collection(`360s`)
    .doc(current360ID)
    .collection(`groups`)
    .doc(groupID)
    .collection(`questionnaires`)
    .doc(questionnaireID)
    .collection(reviewType)
    .doc('sections');

export const doGetGoalAnswers = (accountID, userID, current360ID, groupID, questionnaireID, reviewType, sectionID) => {
  return db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`users`)
    .doc(userID)
    .collection(`360s`)
    .doc(current360ID)
    .collection(`group`)
    .doc(groupID)
    .collection(`questionnaires`)
    .doc(questionnaireID)
    .collection(reviewType)
    .doc(`sections`)
    .collection(sectionID)
    .doc(`answers`);
};

export const doGetGoalStatuses = (accountID, userID, current360ID, groupID, questionnaireID, reviewType, sectionID) => {
  return db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`users`)
    .doc(userID)
    .collection(`360s`)
    .doc(current360ID)
    .collection(`group`)
    .doc(groupID)
    .collection(`questionnaires`)
    .doc(questionnaireID)
    .collection(reviewType)
    .doc(`sections`)
    .collection(sectionID)
    .doc(`status`);
};

export const doGetUserAnswersBySections = (accountID, userID, current360ID, groupID, questionnaireID, reviewType, sectionIDs) => {
  return new Promise(function(resolve, reject) {
    let count = 0;
    let answers = {};

    Object.keys(sectionIDs).map((sectionKey) => {
      // switch(sectionKey) {
      //   case "3T1fQQpDnd9WFVaiemMa":
      //     sectionKey = "-MKR5fFJ_pJ9YyEDFVk7"
      //     break;
      //   case "FxMQtqEc04xPqUNsHEyZ":
      //     sectionKey = "-MKR5s82Mzl088-tRfrd"
      //     break;
      //   case "IslVzCWRnqHD20DlfDCg":
      //     sectionKey = "-MKR5nUIP_QkJpDFNSQm"
      //     break;
      //   case "qGa0BXr79coBNbJVyh3x":
      //     sectionKey = "-MKR5q4YfEC8x7J5TTmk"
      //     break;
      //   default:
      //     sectionKey = sectionKey
      // }

      db.collection(`accounts`)
        .doc(accountID)
        .collection(`users`)
        .doc(userID)
        .collection(`360s`)
        .doc(current360ID)
        .collection(`group`)
        .doc(groupID)
        .collection(`questionnaires`)
        .doc(questionnaireID)
        .collection(reviewType)
        .doc(`sections`)
        .collection(sectionKey)
        .doc(`answers`)
        .get()
        .then((snapshot) => {
          count++;
          // console.log(snapshot.data());
          answers[sectionKey] = {};
          answers[sectionKey]['answers'] = snapshot.data();

          if (Object.keys(sectionIDs).length == count) {
            resolve(answers);
          }
        });
    });
  });
};

export const doGetSelfReviewCompletionPercentage = (accountID, userID, current360ID, groupID, questionnaireID, reviewType) => {
  return new Promise(function(resolve, reject) {
    db.collection(`accounts`)
      .doc(accountID)
      .collection(`users`)
      .doc(userID)
      .collection(`360s`)
      .doc(current360ID)
      .collection(`group`)
      .doc(groupID)
      .collection(`questionnaires`)
      .doc(questionnaireID)
      .collection(reviewType)
      .doc(`status`)
      .get()
      .then((snapshot) => {
        if (snapshot.data()) {
          resolve(snapshot.data()['completionPercentage']);
        } else {
          resolve(0);
        }
      });
  });
};
// db.collection(`accounts`).doc(accountID).collection(`users`).doc(userID).collection(`360s`).doc(current360ID).collection(`groups`).doc(groupID).collection(`questionnaires`).doc(questionnaireID).collection(reviewType).doc('status').get()

// db.ref(`accounts/${accountID}/users/${userID}/360s/${current360ID}/group/${groupID}/questionnaires/${questionnaireID}/${reviewType}/`)

export const doGetCompletedReviewers = (accountID, current360Id, groupId, userId) => {
  console.log(accountID, current360Id, groupId, userId);
  return db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`360s`)
    .doc(current360Id)
    .collection(`groups`)
    .doc(groupId)
    .collection(`users`)
    .doc(userId)
    .collection(`reviewers`)
    .where('completed', '==', true)
    .get();
};

// return db.collection(`accounts`).doc(accountID).collection(`360s`)
// db.ref(`accounts/${accountID}/360s/${current360Id}/groups/${groupId}/users/${userId}/reviewers/`).orderByChild(`completed`).equalTo(true).once('value');

export const doGetReviewers = (accountID, current360Id, groupId, userId) => {
  return db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`360s`)
    .doc(current360Id)
    .collection(`group`)
    .doc(groupId)
    .collection(`users`)
    .doc(userId)
    .doc(`reviewers`);
};

export const doGetCurrentGroupUsersReviewers = (accountID, current360Id, groupId, users, type) => {
  return new Promise(function(resolve, reject) {
    if (type !== '360' && type !== 'Team Assessment') {
      resolve();
    }
    let reviewersById = {};
    let revieweeKeys = [];
    if (Object.keys(users).length > 0) {
      Object.keys(users).map((key) => {
        revieweeKeys.push(key);
      });
      Object.keys(revieweeKeys).map((key, i) => {
        // revieweeKeys[key]]
        const batchOfTenRevieweeKeys = revieweeKeys.splice(0, 10);
        if (batchOfTenRevieweeKeys.length) {
          db.collection(`accounts`)
            .doc(accountID)
            .collection(`reviewers`)
            .where(`userID`, `in`, batchOfTenRevieweeKeys)
            .get()
            .then((snapshot) => {
              if (!snapshot.empty) {
                snapshot.docs.map((doc, i) => {
                  // Check if reviewer matches the same 360ID:
                  if (doc.data()['current360ID'] == current360Id && doc.data()['groupID'] == groupId) {
                    reviewersById[doc.id] = doc.data();
                    if (Object.keys(revieweeKeys).length == i) {
                      resolve(reviewersById);
                    }
                  } else {
                    resolve(reviewersById);
                  }
                });
              } else {
                resolve(reviewersById);
              }
            });
        } else {
          resolve(reviewersById);
        }
      });
    } else {
      resolve(reviewersById);
    }
  });
};

export const doGetReviewerAnswers = (accountID, reviewerID, sectionIDs) => {
  return new Promise(function(resolve, reject) {
    let count = 0;
    let answers = {};
    Object.keys(sectionIDs).map((sectionKey) => {
      db.collection(`accounts`)
        .doc(accountID)
        .collection(`reviewers`)
        .doc(reviewerID)
        .collection(`review`)
        .doc(`sections`)
        .collection(sectionKey)
        .doc(`answers`)
        .get()
        .then((snapshot) => {
          if (snapshot.exists) {
            count++;
            // console.log(snapshot.data());
            answers[sectionKey] = {};
            answers[sectionKey]['answers'] = snapshot.data();

            if (Object.keys(sectionIDs).length == count) {
              // console.log(answers);
              resolve(answers);
            }
          } else {
            // Do it again, but with different `section` reference :|
            db.collection(`accounts`)
              .doc(accountID)
              .collection(`reviewers`)
              .doc(reviewerID)
              .collection(`review`)
              .doc(`section`)
              .collection(sectionKey)
              .doc(`answers`)
              .get()
              .then((snapshot) => {
                count++;
                // console.log(snapshot.data());
                answers[sectionKey] = {};
                answers[sectionKey]['answers'] = snapshot.data();

                if (Object.keys(sectionIDs).length == count) {
                  // console.log(answers);
                  resolve(answers);
                }
              });
          }
        });
    });
  });
};
// db.ref(`accounts/${accountID}/reviewers/${reviewerID}/review/`).once('value')

export const doGetAccountBranding = (accountID) => db.collection(`accounts`).doc(accountID);

export const doGetAccountsList = () => db.collection(`accounts`).get();

export const doGetCurrentUser = (accountID, current360Id, groupId, userId) =>
  db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`users`)
    .doc(userId);

export const doUpdateReviewer = (accountID, reviewerId, userName, userEmail, relationship, revieweeId, current360ID, groupId) => {
  return new Promise(function(resolve, reject) {
    db.collection(`accounts`)
      .doc(accountID)
      .collection(`reviewers`)
      .doc(reviewerId)
      .set(
        {
          name: userName,
          email: userEmail.replace(/\s+/g, '').toLowerCase(),
          relationship: relationship,
        },
        { merge: true }
      )
      .then((data) => {
        db.collection(`accounts`)
          .doc(accountID)
          .collection(`360s`)
          .doc(current360ID)
          .collection(`groups`)
          .doc(groupId)
          .collection(`users`)
          .doc(revieweeId)
          .collection(`reviewers`)
          .doc(reviewerId)
          .set(
            {
              name: userName,
              email: userEmail.replace(/\s+/g, '').toLowerCase(),
              relationship: relationship,
            },
            { merge: true }
          )
          .then((data) => {
            resolve();
          });
      });
  });
};

export const deleteUserFromGroup = (accountId, current360ID, currentGroupId, userToDeleteID) => {
  return new Promise(function(resolve, reject) {
    db.collection(`accounts`)
      .doc(accountId)
      .collection(`360s`)
      .doc(current360ID)
      .collection(`groups`)
      .doc(currentGroupId)
      .collection(`users`)
      .doc(userToDeleteID)
      .delete()
      .then(() => {
        db.collection(`accounts`)
          .doc(accountId)
          .collection(`360s`)
          .doc(current360ID)
          .update({
            userIDs: firebase.firestore.FieldValue.arrayRemove(userToDeleteID),
          })
          .then(() => {
            db.collection(`accounts`)
              .doc(accountId)
              .collection(`users`)
              .doc(userToDeleteID)
              .collection(`360s`)
              .doc(current360ID)
              .delete()
              .then(() => {
                resolve();
              });
          });
      });
  });
};

export const doUpdateCurrentUser = (accountID, current360Id, groupId, userId, userForename, userSurname, userEmail) => {
  return new Promise(function(resolve, reject) {
    db.collection(`accounts`)
      .doc(accountID)
      .collection(`users`)
      .doc(userId)
      .set(
        {
          email: userEmail.replace(/\s+/g, '').toLowerCase(),
          forename: userForename,
          surname: userSurname,
        },
        { merge: true }
      )
      .then((data) => {
        db.collection(`accounts`)
          .doc(accountID)
          .collection(`360s`)
          .doc(current360Id)
          .collection(`groups`)
          .doc(groupId)
          .collection(`users`)
          .doc(userId)
          .set(
            {
              email: userEmail.replace(/\s+/g, '').toLowerCase(),
              forename: userForename,
              surname: userSurname,
            },
            { merge: true }
          )
          .then((data) => {
            resolve();
          });
      });
  });
};

export const doGetLoggedInUserDetails = (uid) =>
  db
    .collection(`admins`)
    .doc(`${uid}`)
    .get();

export const lockGroupReviewers = (accountID, groupID, current360ID, lockedStatus) => {
  return new Promise((resolve, reject) => {
    db.collection(`accounts`)
      .doc(accountID)
      .collection(`360s`)
      .doc(current360ID)
      .collection(`groups`)
      .doc(groupID)
      .update({ locked: lockedStatus })
      .then(() => {
        resolve('success');
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const get360details = (accountID, current360ID) => {
  return db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`360s`)
    .doc(current360ID)
    .get();
};

export const doDuplicate360 = (accountID, title, original360Key, comparisonCheck) => {
  return new Promise((resolve, reject) => {
    //get the original 360 details to copy from
    db.collection(`accounts`)
      .doc(accountID)
      .collection(`360s`)
      .doc(original360Key)
      .get()
      .then((dupeFromDoc) => {
        const dupeDoc = dupeFromDoc.data();
        //create a new doc with the new and original doc details
        //and a flag to show it is a comparison project for locking the settings
        //and a reference to the original questionnaire
        db.collection(`accounts`)
          .doc(accountID)
          .collection(`360s`)
          .add({
            type: dupeDoc.type,
            title: title,
            client: dupeDoc.client,
            createdDate: Date.now(),
            expirationDate: null,
            archived: false,
            userIDs: [],
            statusCode: 1,
            comparisonProject: comparisonCheck,
            duplicatedFrom: original360Key,
            settings: dupeDoc.settings,
            questionnaireID: dupeDoc.questionnaireID,
          })
          .then((data) => {
            let threesixtyKey = data.id;
            //copy over the relationships
            db.collection(`accounts`)
              .doc(accountID)
              .collection(`360s`)
              .doc(original360Key)
              .collection(`relationships`)
              .get()
              .then((docs) => {
                let originalRelationships = {};
                //paste the relationships into the new 360
                docs.forEach((doc) => {
                  originalRelationships[doc.id] = doc.data();
                  let dataCopy = doc.data();
                  // add a sortOrder value if relationships don't have one
                  if (doc.data().sortOrder === undefined) {
                    dataCopy.sortOrder = Object.keys(originalRelationships).length - 1;
                  }
                  db.collection(`accounts`)
                    .doc(accountID)
                    .collection(`360s`)
                    .doc(threesixtyKey)
                    .collection(`relationships`)
                    .doc(doc.id)
                    .set(dataCopy);
                });
                //copy the reminder text
                db.collection(`accounts`)
                  .doc(accountID)
                  .collection(`360s`)
                  .doc(original360Key)
                  .collection(`email_text`)
                  .doc(`reminders`)
                  .get()
                  .then((remDoc) => {
                    //paste the reminder text into the new 360
                    db.collection(`accounts`)
                      .doc(accountID)
                      .collection(`360s`)
                      .doc(threesixtyKey)
                      .collection(`email_text`)
                      .doc(`reminders`)
                      .set(remDoc.data());
                    //return the new 360 key for redirecting
                    resolve(threesixtyKey);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              });
          });
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

export const getPrevious360 = (accountID, userID, previous360ID) => {
  return db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`users`)
    .doc(userID)
    .collection(`360s`)
    .doc(previous360ID)
    .get();
};
export const getPrevious360Completion = (accountID, userID, previous360ID, groupID, questionnaireID) => {
  return new Promise((resolve, reject) => {
    db.collection(`accounts`)
      .doc(accountID)
      .collection(`users`)
      .doc(userID)
      .collection(`360s`)
      .doc(previous360ID)
      .collection(`group`)
      .doc(groupID)
      .collection(`questionnaires`)
      .doc(questionnaireID)
      .collection(`self-review`)
      .doc(`status`)
      .get()
      .then((res) => {
        let previousComplete = {};
        previousComplete[`userCompletion`] = res.data();
        db.collection(`accounts`)
          .doc(accountID)
          .collection(`360s`)
          .doc(previous360ID)
          .collection(`groups`)
          .doc(groupID)
          .collection(`users`)
          .doc(userID)
          .collection(`reviewers`)
          .get()
          .then((docs) => {
            previousComplete['reviewers'] = docs.size;
            resolve(previousComplete);
          });
      });
  });
};

export const getPreviousGroups = (accountID, previous360ID) => {
  return db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`360s`)
    .doc(previous360ID)
    .collection(`groups`)
    .get();
};

export const importPreviousGroup = (accountID, previous360ID, previousGroupID) => {
  return db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`360s`)
    .doc(previous360ID)
    .collection(`groups`)
    .doc(previousGroupID)
    .collection(`users`)
    .get();
};

export const getPreviousGroupName = (accountID, previous360ID) => {
  return db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`360s`)
    .doc(previous360ID)
    .get();
};

export const importGroupUsers = (accountID, current360ID, groupID, userID, userData) => {
  return new Promise((resolve, reject) => {
    db.collection(`accounts`)
      .doc(accountID)
      .collection(`360s`)
      .doc(current360ID)
      .collection(`groups`)
      .doc(groupID)
      .collection(`users`)
      .doc(userID)
      .set({
        completed: false,
        email: userData.email,
        forename: userData.forename,
        surname: userData.surname,
      })
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
    // console.log(userID);
    // console.log({ completed: false, email: userData.email, forename: userData.forename, surname: userData.surname });
    // resolve();
  });
};

export const importGroupAddDetails = (accountID, userID, userData, current360Id, currentGroupId, currentQuestionnaireId) => {
  console.log(userID);
  console.log(userData);
  console.log(accountID);
  console.log(current360Id);
  return new Promise(function(resolve, reject) {
    db.collection(`accounts`)
      .doc(accountID)
      .collection(`360s`)
      .doc(current360Id)
      .collection(`groups`)
      .doc(currentGroupId)
      .collection(`users`)
      .doc(userID)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          reject('User already exists in this Group!');
        } else {
          // db.collection(`accounts`).doc(accountID).collection(`360s`).where("userIDs", "array-contains", newUserID).get().then((snapshot) => {
          db.collection(`accounts`)
            .doc(accountID)
            .collection(`360s`)
            .doc(current360Id)
            .get()
            .then((snapshot) => {
              let userIDs = [];

              if (snapshot.data()['userIDs']) {
                userIDs = snapshot.data()['userIDs'];
              }
              console.log(userIDs);
              if (userIDs.includes(userID)) {
                reject('User already exists in another group!');
              } else {
                // Push user to Group
                db.collection('accounts')
                  .doc(accountID)
                  .collection('360s')
                  .doc(current360Id)
                  .collection('groups')
                  .doc(currentGroupId)
                  .collection('users')
                  .doc(userID)
                  .set(
                    {
                      email: userData['email'].replace(/\s+/g, '').toLowerCase(),
                      forename: userData['forename'],
                      surname: userData['surname'],
                    },
                    { merge: true }
                  )
                  .then(() => {
                    // assign360ToUser:

                    db.collection('accounts')
                      .doc(accountID)
                      .collection(`users`)
                      .doc(userID)
                      .collection(`360s`)
                      .doc(current360Id)
                      .collection(`group`)
                      .doc(currentGroupId)
                      .collection(`questionnaires`)
                      .doc(currentQuestionnaireId)
                      .collection(`self-review`)
                      .doc(`status`)
                      .set({
                        completed: false,
                        completionPercentage: 0,
                      })
                      .then((data) => {
                        db.collection('accounts')
                          .doc(accountID)
                          .collection(`users`)
                          .doc(userID)
                          .collection(`360s`)
                          .doc(current360Id)
                          .set(
                            {
                              groupID: currentGroupId,
                              questionnaireId: currentQuestionnaireId,
                            },
                            { merge: true }
                          )
                          .then((data) => {
                            db.collection(`accounts`)
                              .doc(accountID)
                              .collection(`360s`)
                              .doc(current360Id)
                              .update(
                                {
                                  userIDs: firebase.firestore.FieldValue.arrayUnion(userID),
                                },
                                { merge: true }
                              )
                              .then(() => {
                                resolve();
                              });
                          });
                      });
                  });
              }
            });
        }
      });
  });

  // return db.ref(`360/${current360Id}/groups/${currentGroupId}/`).child(`users`).update(
  //   newUser
  // )

  // return db.ref(`360/${current360Id}/groups/${currentGroupId}/users/`).push(
  //   userData
  // )
};

export const saveReportInformation = (accountID, current360ID, currentGroupID, reportType, reportData) => {
  return db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`360s`)
    .doc(current360ID)
    .collection(`groups`)
    .doc(currentGroupID)
    .collection(`reports`)
    .doc(reportType)
    .set({ reportData, updateByReview: false });
  ///accounts/irKtJiQYpVUsweL6zTYpaEllPct1/360s/NN0pX5f0d2fFLrH1YhWZ/groups/JCGP9eVn9uzqwcyJXOH7/users
};

export const getReportInformation = (accountID, current360ID, currentGroupID, reportType) => {
  console.log(accountID, current360ID, currentGroupID, reportType);
  return db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`360s`)
    .doc(current360ID)
    .collection(`groups`)
    .doc(currentGroupID)
    .collection(`reports`)
    .doc(reportType)
    .get();
};

export const fillInAnswers = (accountID, questionnaireID, current360ID, currentGroupID, reviewees, reviewers, responses, type) => {
  //this returns either 0, 1, or  2
  //0 = completely random
  //1 = lower weighting
  //2 = higher weighting

  const valFunc = (weighting, valOne, valTwo, valThree, valDefault) => {
    switch (weighting) {
      case 0:
        return valOne;

      case 1:
        return valTwo;

      case 2:
        return valThree;

      default:
        return valDefault;
    }
  };

  if (type !== 'Team Assessment') {
    Object.keys(reviewees).map((revieweeID) => {
      let userAnswers = {};
      //build answers
      Object.keys(responses).map((secKey) => {
        userAnswers[secKey] = {};
        let revMax = Math.floor(Math.random() * (5 - 3 + 1));
        let min = valFunc(revMax, 0, 0, 3, 0);
        let max = valFunc(revMax, 5, 3, 5, 5);
        Object.keys(responses[secKey].questions).map((ansKey) => {
          if (responses[secKey].questions[ansKey].answerType === 'MultiChoice') {
            userAnswers[secKey][ansKey] = `${Math.floor(Math.random() * (max - min + 1) + min)}`;
          } else {
            userAnswers[secKey][ansKey] = 'Test text information';
          }
        });

        db.collection(`accounts`)
          .doc(accountID)
          .collection(`users`)
          .doc(revieweeID)
          .collection(`360s`)
          .doc(current360ID)
          .collection(`group`)
          .doc(currentGroupID)
          .collection(`questionnaires`)
          .doc(questionnaireID)
          .collection(`self-review`)
          .doc(`sections`)
          .collection(secKey)
          .doc(`answers`)
          .set(userAnswers[secKey])
          .then((res) => {
            db.collection(`accounts`)
              .doc(accountID)
              .collection(`users`)
              .doc(revieweeID)
              .collection(`360s`)
              .doc(current360ID)
              .collection(`group`)
              .doc(currentGroupID)
              .collection(`questionnaires`)
              .doc(questionnaireID)
              .collection(`self-review`)
              .doc(`status`)
              .update({
                completed: true,
                completionPercentage: 100,
              })
              .then(() => {
                db.collection(`accounts`)
                  .doc(accountID)
                  .collection(`360s`)
                  .doc(current360ID)
                  .collection(`groups`)
                  .doc(current360ID)
                  .collection(`users`)
                  .doc(revieweeID)
                  .update({ completed: true })
                  .then((res) => {
                    console.log(res);
                  });
              });
          });
      });
    });
  }

  Object.keys(reviewers).map((reviewerID) => {
    let userAnswers = {};

    Object.keys(responses).map((secKey) => {
      userAnswers[secKey] = {};

      let revMax = Math.floor(Math.random() * (5 - 3 + 1));
      let min = valFunc(revMax, 0, 0, 3, 0);
      let max = valFunc(revMax, 5, 3, 5, 5);
      Object.keys(responses[secKey].questions).map((ansKey) => {
        if (responses[secKey].questions[ansKey].answerType === 'MultiChoice') {
          userAnswers[secKey][ansKey] = `${Math.floor(Math.random() * (max - min + 1) + min)}`;
        } else {
          userAnswers[secKey][ansKey] = 'Test text information';
        }
      });
      db.collection(`accounts`)
        .doc(accountID)
        .collection(`reviewers`)
        .doc(reviewerID)
        .collection(`review`)
        .doc(`sections`)
        .collection(secKey)
        .doc(`answers`)
        .set(userAnswers[secKey])
        .then(() => {
          db.collection(`accounts`)
            .doc(accountID)
            .collection(`360s`)
            .doc(current360ID)
            .collection(`groups`)
            .doc(currentGroupID)
            .collection(`users`)
            .doc(reviewers[reviewerID].userID)
            .collection(`reviewers`)
            .doc(reviewerID)
            .update({
              completed: true,
            })
            .then(() => {
              return;
            });
        });

      ///accounts/irKtJiQYpVUsweL6zTYpaEllPct1/reviewers/HZ1ntjkKM1OHfRScdVzw/review/sections
    });
  });
};

export const markAsComplete = (accountID, current360ID, reviewers) => {
  Object.keys(reviewers).map((key, n) => {
    return new Promise((resolve, reject) => {
      db.collection(`accounts`)
        .doc(accountID)
        .collection(`reviewers`)
        .doc(key)
        .collection(`review`)
        .doc(`status`)
        .get()
        .then((doc) => {
          if (doc.data().completionPercentage === 100 && doc.data().completed === false) {
            console.log(reviewers[key]);
            db.collection(`accounts`)
              .doc(accountID)
              .collection(`reviewers`)
              .doc(key)
              .collection(`review`)
              .doc(`status`)
              .update({ completed: true })
              .then(() => {
                db.collection(`accounts`)
                  .doc(accountID)
                  .collection(`360s`)
                  .doc(current360ID)
                  .collection(`groups`)
                  .doc(reviewers[key].groupID)
                  .collection(`users`)
                  .doc(reviewers[key].userID)
                  .collection(`reviewers`)
                  .doc(key)
                  .update({
                    completed: true,
                  })
                  .then(() => {
                    console.log('done');
                    resolve();
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              })
              .catch((err) => {
                console.log(err);
              });
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  });

  return Promise.all();
};

export const exportResults = (accountID, current360ID, currentGroupID, currentQuestionnaireID, reviewers, reviewees, questionnaireSections) => {
  const totalReviewees = Object.keys(reviewees).length;
  const totalReviewers = Object.keys(reviewers).length;
  let revieweeScores = {};
  let reviewerScores = {};
  //loop through the reviewees to get their scores
  const getRevieweeScores = new Promise((resolve, reject) => {
    Object.keys(reviewees).map((revieweeKey, n) => {
      revieweeScores[revieweeKey] = {};
      Object.keys(questionnaireSections).map((secID, k) => {
        // /accounts/WPNtZUZkaMQ9NVCTvuENmGyS4ya2/users/tfCXCr4MCdmgXLDvgcAx/360s/MiQMgobl3SUR5YxKLJ32/group/53aPMyGxxJTgU6FHTNKp/questionnaires/m2o0ubsniFW0AHZUwKIX/self-review/sections/1653403700794/answers
        db.collection(`accounts`)
          .doc(accountID)
          .collection(`users`)
          .doc(revieweeKey)
          .collection(`360s`)
          .doc(current360ID)
          .collection(`group`)
          .doc(currentGroupID)
          .collection(`questionnaires`)
          .doc(currentQuestionnaireID)
          .collection(`self-review`)
          .doc(`sections`)
          .collection(`${secID}`)
          .doc(`answers`)
          .get()
          .then((doc) => {
            revieweeScores[revieweeKey][secID] = doc.data();
            //if this is the final section of the final reviewee, resolve the promise
            if (k + 1 === Object.keys(questionnaireSections).length && n + 1 === Object.keys(reviewees).length) {
              resolve(revieweeScores);
            }
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    });
  });

  // /accounts/WPNtZUZkaMQ9NVCTvuENmGyS4ya2/reviewers/1O3w6nPQHP9sqM6Zn8wb/review/sections/1653403700794/answers
  const getReviewerScores = new Promise((resolve, reject) => {
    Object.keys(reviewers).map((reviewerKey, n) => {
      reviewerScores[reviewerKey] = {};
      Object.keys(questionnaireSections).map((secID, k) => {
        db.collection(`accounts`)
          .doc(accountID)
          .collection(`reviewers`)
          .doc(reviewerKey)
          .collection(`review`)
          .doc(`sections`)
          .collection(`${secID}`)
          .doc(`answers`)
          .get()
          .then((doc) => {
            reviewerScores[reviewerKey][secID] = doc.data();
            //if this is the final section of the final reviewers, resolve the promise
            if (k + 1 === Object.keys(questionnaireSections).length && n + 1 === Object.keys(reviewers).length) {
              resolve(reviewerScores);
            }
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    });
  });

  return Promise.all([getRevieweeScores, getReviewerScores]);
};

export const doUpdateProject = (accountID, current360Id, title, emailData) => {
  return db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`360s`)
    .doc(current360Id)
    .set(
      {
        title,
      },
      { merge: true }
    );
};

export const doUpdateReviewerEmailStatus = (accountID, reviewerId, revieweeId, current360ID, groupId, emailStatus) => {
  return new Promise(function(resolve, reject) {
    db.collection(`accounts`)
      .doc(accountID)
      .collection(`reviewers`)
      .doc(reviewerId)
      .set(
        {
          emailStatus,
        },
        { merge: true }
      )
      .then(() => {
        db.collection(`accounts`)
          .doc(accountID)
          .collection(`360s`)
          .doc(current360ID)
          .collection(`groups`)
          .doc(groupId)
          .collection(`users`)
          .doc(revieweeId)
          .collection(`reviewers`)
          .doc(reviewerId)
          .set(
            {
              emailStatus,
            },
            { merge: true }
          )
          .then(() => {
            resolve();
          });
      });
  });
};

export const doGetCurrentGroupUser = (accountID, current360Id, currentGroupId, revieweeID) => {
  return db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`360s`)
    .doc(current360Id)
    .collection(`groups`)
    .doc(currentGroupId)
    .collection(`users`)
    .doc(revieweeID)
    .get();
};

export const doUpdateCurrentGroupUser = (accountID, current360Id, currentGroupId, revieweeID, newKey, newValue) => {
  return new Promise(function(resolve, reject) {
    db.collection(`accounts`)
      .doc(accountID)
      .collection(`360s`)
      .doc(current360Id)
      .collection(`groups`)
      .doc(currentGroupId)
      .collection(`users`)
      .doc(revieweeID)
      .set(
        {
          [newKey]: newValue,
        },
        { merge: true }
      )
      .then(() => {
        resolve();
      });
  });
};

export const addDefaultQuestionnaireLabels = (accountID, questionnaireID) => {
  return new Promise(function(resolve, reject) {
    const ratingLabels = [
      { score: 0, text: 'Unable to comment', frequency: '' },
      { score: 1, text: 'Never', frequency: '0% of the time' },
      { score: 2, text: 'Rarely', frequency: '1% - 35% of the time' },
      { score: 3, text: 'Some of the time', frequency: '36% - 59% of the time' },
      { score: 4, text: 'Quite often', frequency: '60% - 89% of the time' },
      { score: 5, text: 'Frequently if not always', frequency: 'over 90% of the time' },
    ]

    const addLabel = (label, labelIndex) => {
      const {score, text, frequency} = label
      return new Promise(function(resolve, reject) {
        db.collection(`accounts`)
        .doc(accountID)
        .collection('questionnaires')
        .doc(questionnaireID)
        .collection(`labels`)
        .add(
          {
            score,
            text,
            frequency,
            sortOrder: labelIndex,
          },
          { merge: true }
        )
        .then(() => {
          resolve(true)
        })
        .catch(() => {
          resolve(false)
        })
      })
    }

    const addLabelsPromises = ratingLabels.map((label, labelIndex) => {
      return addLabel(label, labelIndex)
    })

    Promise.all(addLabelsPromises)
      .then(values => {
        if (!values.includes(false)) {
          resolve(true)
        }
      })
  })
};

export const doGetQuestionnaireLabels = (accountID, questionnaireID) =>
  db
    .collection(`accounts`)
    .doc(accountID)
    .collection('questionnaires')
    .doc(questionnaireID)
    .collection(`labels`)
    .get();

export const updateQuestionnaireLabel = (accountID, questionnaireID, labelID, labelText) => {
  return db
    .collection(`accounts`)
    .doc(accountID)
    .collection('questionnaires')
    .doc(questionnaireID)
    .collection(`labels`)
    .doc(labelID)
    .set(
      {
        text: labelText,
      },
      { merge: true }
    );
};
