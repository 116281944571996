import React, { Component } from 'react';
import LoadingState from '../global/loading-state';
import PageHeader from '../PageHeader';
import ChartRowDF from './ChartRowDF';
import {average} from '../../helpers'


class DetailedFeedbackChart extends Component {
  constructor(props) {
    super(props);
    this.chartRowDFLoadingUpdate = this.chartRowDFLoadingUpdate.bind(this);
    this.state = {
      loading: true,
      chartRowDFLoading: true,
      chartPagesUpdated: false,
      reRenderStage: 0,
    };
  }

  componentDidMount() {
    const {reportType, sectionID, sectionDetails, questionDetails, multiChoiceQuestionIDs, relationshipKeys, sectionAveragesByRelationshipCurrent, sectionAveragesByRelationshipPrevious} = this.props
    const responseKeys = [5,4,3,2,1,0]

    const questions = sectionDetails.questions
    let sectionAverageByRelationshipCurrent = {}
    let sectionAverageByRelationshipPrevious = {}
    let sectionResponsesByRelationshipCurrent = {}
    let sectionResponsesByRelationshipPrevious = {}
    let questionAveragesByRelationshipCurrent = {}
    let questionResponsesByRelationshipCurrent = {}
    let chartPages = [{lowerLimit: 0, upperLimit: multiChoiceQuestionIDs.length}]

    if (relationshipKeys) {
      relationshipKeys.forEach(relationshipID => {
        let currentSectionAverage
        let previousSectionAverage
        if (reportType === 'Group') {
          currentSectionAverage = +sectionAveragesByRelationshipCurrent[relationshipID].averagesBySection[sectionID]
          questionAveragesByRelationshipCurrent[relationshipID] = this.props.questionAveragesByRelationshipCurrent[relationshipID].sectionAnswersByType.answersBySection[sectionID]
          questionResponsesByRelationshipCurrent[relationshipID] = this.props.questionResponsesByRelationshipCurrent[relationshipID][sectionID]
        } else {
          currentSectionAverage = +sectionAveragesByRelationshipCurrent[relationshipID][sectionID]
          questionAveragesByRelationshipCurrent[relationshipID] = this.props.questionAveragesByRelationshipCurrent[relationshipID]
          questionResponsesByRelationshipCurrent = this.props.questionResponsesByRelationshipCurrent
        }
        if (reportType === 'Comparison') {
          previousSectionAverage = +sectionAveragesByRelationshipPrevious[relationshipID][sectionID]
          sectionAverageByRelationshipPrevious[relationshipID] = previousSectionAverage
          sectionResponsesByRelationshipPrevious[relationshipID] = this.props.sectionResponsesByRelationshipPrevious[relationshipID][sectionID]
        }
        sectionAverageByRelationshipCurrent[relationshipID] = currentSectionAverage
        sectionResponsesByRelationshipCurrent[relationshipID] = this.props.sectionResponsesByRelationshipCurrent[relationshipID][sectionID]
      })
    }

    this.setState({ 
      sectionAverageByRelationshipCurrent, 
      sectionResponsesByRelationshipCurrent, 
      responseKeys,
      sectionAverageByRelationshipPrevious,
      sectionResponsesByRelationshipPrevious,
      questions,
      questionAveragesByRelationshipCurrent,
      questionResponsesByRelationshipCurrent,
      chartPages,
      loading: false,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const {multiChoiceQuestionIDs, lastMultichoiceSectionID, sectionID, upadteMultichoiceSectionsRerenderedForPDF} = this.props;
    const {chartPagesUpdated, reRenderStage, loading} = this.state;

    if (prevProps.pdfRequested !== this.props.pdfRequested) {
      if (!loading) {
        this.setState({ reRenderStage: 1 });
      }
    }

    if (prevState.chartRowDFLoading !== this.state.chartRowDFLoading) {
      let MaxAvailableSpace = 600;
      // gets row height for the competency (+1 to account for rounding from offsetHeight)
      const sectionRowHeight = this.sectionRow.offsetHeight + 1;
      const numberOfQuestions = multiChoiceQuestionIDs.length;
      let totalSpaceRequired = sectionRowHeight;
      let multiChoiceQuestionHeights = [];
      // loops through each question, calculates it's row height (+1 for rounding again), then pushes heights to a new array. Also calculates totale space required for all of the rows.
      multiChoiceQuestionIDs.forEach((questionID, questionIndex) => {
        const questionRowHeight = this[`questionRow${questionIndex}`].offsetHeight + 1;
        multiChoiceQuestionHeights.push(questionRowHeight);
        totalSpaceRequired += questionRowHeight;
      })

      // sets an array for each new page to be added, page data will contain index limits to prevent overflow
      let updatedChartPages = []

      // initial check to see if chart will fit on one page
      if (totalSpaceRequired >= MaxAvailableSpace) {
        // if chart must split, we loop through each question height and recalculate until the split point is reached and index limits are updated for the page
        let currentPageSpace = MaxAvailableSpace - sectionRowHeight;
        let lowerLimit = 0;
        let upperLimit = numberOfQuestions;
        multiChoiceQuestionHeights.forEach((questionHeight, questionIndex) => {
          if (currentPageSpace - questionHeight > 0) {
            currentPageSpace -= questionHeight;
          } else {
            // split point reached, upperLimit is updated and current page limits are pushed to updatedChartPages
            upperLimit = questionIndex;
            updatedChartPages.push({lowerLimit, upperLimit})
            // Prepares new page by resetting available space and limits (accounting for current question moved to it)
            lowerLimit = questionIndex;
            currentPageSpace = MaxAvailableSpace - (questionHeight);
          }
        })
        // pushes remaining questions onto one last page
        updatedChartPages.push({lowerLimit, upperLimit: numberOfQuestions})
      } else {
        // if chart can go on one page, it receives default index limits and is pushed to updatedChartPages
        const lowerLimit = 0;
        const upperLimit = numberOfQuestions;
        updatedChartPages.push({lowerLimit, upperLimit})
      }
      this.setState({chartPages: updatedChartPages, chartPagesUpdated: true, reRenderStage: 2})
    }

    if (prevState.reRenderStage !== this.state.reRenderStage) {
      if (this.state.reRenderStage === 2) {
        if (lastMultichoiceSectionID === sectionID) {
          upadteMultichoiceSectionsRerenderedForPDF()
        }
      }
    }
  }

  chartRowDFLoadingUpdate() {
    // updates when ChartRowDF component has loaded, to ensure height data is correct
    this.setState({chartRowDFLoading: false})
  }

  render() {
    const {reportType, sectionID, stepNumber, sectionDetails, multiChoiceQuestionIDs, sectionAverageSelfCurrent, colours, sectionAverageSelfPrevious, sectionResponsesSelfPrevious, dfSectionRelationshipKeys, relationshipKeys, current360Relationships, sectionCombinedReviewersAverage, sectionCombinedReviewerResponses, sectionDistributionArray, questionDistributionArray, questionAveragesSelfCurrent, questionAverageSelfPrevious, questionResponsesSelfPrevious, questionCombinedReviewersAverage, questionCombinedReviewersResponses, questionAveragesByRelationshipPrevious, questionResponsesByRelationshipPrevious, sectionResponsesSelfCurrent} = this.props;
    const {loading, sectionAverageByRelationshipCurrent, sectionResponsesByRelationshipCurrent, sectionAverageByRelationshipPrevious, sectionResponsesByRelationshipPrevious, questions, questionAveragesByRelationshipCurrent, questionResponsesByRelationshipCurrent, chartPages, reRenderStage} = this.state
    
    return !loading ? (

      chartPages.map((pageLimits, pageIndex) => {
        const {lowerLimit, upperLimit} = pageLimits
        return (
          <div
            key={sectionID + pageIndex} 
            className='mb4 page-break user-report__content user-report__content--no-pad'
            style={{ width: reRenderStage === 1 ? '158mm' : ''}}
          >
            <div className='average-rating-section'>
              <PageHeader h2Class='mt4' h2Text='Part 2: ' strong={`Step ${stepNumber}`} h3Text={`Your detailed feedback ${pageIndex > 0 ? '(continued)' : ''}`} />

              <div className='ba b--moon-gray mb10 table'>
                {/* Headings row */}
                <div className='row flex bg-light-gray'>
                  <div className='w-30 pt1 pb0'/>
                  <div className='w-70'>
                    <div className='w-100 flex flex-wrap table__row pl1'>
                      {reportType === 'Self' || reportType === 'Team' ? (
                        <></>
                      ) : (
                        <div className='w-20 pt1 pb0'>
                          <strong>Relationship</strong>
                        </div>
                      )}
                      <div className={`w-${reportType === 'Self' || reportType === 'Team' ? '7' : '5'}0 pt1 pb0 ${reportType === 'Comparison' ? 'flex' : ''}`}>
                        <strong>Average Rating</strong>
                        {reportType === 'Comparison' && (
                          <>
                            <span
                              className='bg-blue h-100 db ml1 mr1 ba'
                              style={{
                                height: '70%',
                                width: '15px',
                              }}
                            />
                            <strong>Current average rating</strong>
                            <span
                              className='bg-blue h-100 db ml1 mr1 ba'
                              style={{
                                height: '70%',
                                width: '15px',
                                backgroundColor: `#0051ff`,
                                opacity: '0.4',
                              }}
                            />
                            <strong>Previous average rating</strong>
                          </>
                        )}
                      </div>
                      <div className='w-30 pt1 pb0'>
                        <div className='tc'>
                          <strong>Number of responses</strong>
                        </div>
                        <div className='w-100 flex'>
                          {[5, 4, 3, 2, 1, '?'].map((c, i) => {
                            return <p key={i} className='w-100 mv0 tc'>{c}</p>;
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Competency Row */}
                {pageIndex === 0 && (
                  <div 
                    className='row pt1 pb1 pl2 flex bt bb b--moon-gray'
                    ref={(sectionRow) => {
                      this.sectionRow = sectionRow
                    }}
                  >
                    <div className='w-30'>
                      <p className='ma0 f6'>
                        <strong>{sectionDetails.title}</strong>
                      </p>
                    </div>

                    <div className='w-70'>
                      {/* Distribution Row */}
                      {reportType === 'Group' && (
                        <ChartRowDF
                          headingContent='Distribution' 
                          colour='gray'
                          distribution={true}
                          distributionArray={sectionDistributionArray}
                          colours={colours}
                        />
                      )}

                      {/* Current Self Row */}
                      {reportType !== 'Team' && <ChartRowDF
                        headingContent='Self' 
                        colour='blue'
                        average={+sectionAverageSelfCurrent}
                        responses={sectionResponsesSelfCurrent}
                        bold={true}
                        selfRow={true}
                        chartRowDFLoadingUpdate={this.chartRowDFLoadingUpdate}
                        reRenderStage={this.state.reRenderStage}
                        sectionID={sectionID}
                        reportType={reportType}
                      />}

                      {/* Previous Self Row */}
                      {reportType === 'Comparison' && (
                        <ChartRowDF
                          average={+sectionAverageSelfPrevious}
                          colour='#0051ff'
                          opacity='0.4'
                          responses={sectionResponsesSelfPrevious}
                          bold={true}
                          selfRow={true}
                        />
                      )}

                      {/* All Reviewers */}
                      {reportType === 'Group' && (
                        <ChartRowDF
                          headingContent='All Reviewers' 
                          colour='gray'
                          average={+sectionCombinedReviewersAverage}
                          allReviewers={true}
                          responses={sectionCombinedReviewerResponses}
                          bold={true}
                        />
                      )}

                      {/* Reviewer Rows By Relationship */}
                      {reportType !== 'Self' && dfSectionRelationshipKeys.map(relationshipID => {
                        const relationshipName = current360Relationships[relationshipID]['name']
                        const relationshipColour = current360Relationships[relationshipID]['colour']
                        const sectionAverageCurrent = sectionAverageByRelationshipCurrent[relationshipID]
                        const sectionAveragePrevious = sectionAverageByRelationshipPrevious[relationshipID]
                        const sectionResponsesCurrent = sectionResponsesByRelationshipCurrent[relationshipID]
                        const sectionResponsesPrevious = sectionResponsesByRelationshipPrevious[relationshipID]
                        return (
                          <div key={relationshipID}>

                            <ChartRowDF
                              headingContent={relationshipName} 
                              colour={relationshipColour}
                              average={+sectionAverageCurrent}
                              responses={sectionResponsesCurrent}
                              chartRowDFLoadingUpdate={reportType === 'Team' ? this.chartRowDFLoadingUpdate : undefined}
                              reRenderStage={this.state.reRenderStage}
                              reportType={reportType}
                            />

                            {reportType === 'Comparison' && (
                              <ChartRowDF
                                average={+sectionAveragePrevious}
                                colour={relationshipColour}
                                opacity='0.4'
                                responses={sectionResponsesPrevious}
                              />
                            )}
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )}
                {/* Question Rows */}
                {multiChoiceQuestionIDs.map((questionID, questionIndex) => {
                  const {questionTitle, sortOrder} = questions[questionID]
                  let distributionArray;
                  if (questionDistributionArray) {
                    distributionArray = questionDistributionArray[questionID];
                  }
                  let questionAverageSelfCurrent
                  if (questionAveragesSelfCurrent) {
                    questionAverageSelfCurrent = questionAveragesSelfCurrent[questionID];
                  }
                  let questionResponsesSelfCurrent;
                  if (this.props.questionResponsesSelfCurrent) {
                    questionResponsesSelfCurrent = this.props.questionResponsesSelfCurrent[questionID];
                  } else {
                    questionResponsesSelfCurrent = [0,0,0,0,0,0];
                    questionResponsesSelfCurrent[questionAverageSelfCurrent] = 1;
                  }

                  return questionIndex >= lowerLimit && questionIndex < upperLimit && (
                    <div 
                      key={questionID}
                      className='row pt1 pb1 pl2 flex bt b--moon-gray'
                      ref={(questionRow) => {this[`questionRow${questionIndex}`] = questionRow}}
                    >
                      {/* Question Title */}
                      <div className='w-30 pr3 class'>
                        <p className='ma0'>{questionTitle}</p>
                      </div>
                      {/* Question Data Rows */}
                      <div className='w-70 flex flex-wrap'>

                        {/* Distribution Row */}
                        {reportType === 'Group' && distributionArray && (
                          <ChartRowDF
                            headingContent='Distribution' 
                            colour='gray'
                            distribution={true}
                            distributionArray={questionDistributionArray[questionID]}
                            colours={colours}
                            ignoreResponses={true}
                          />
                        )}


                        {/* Current Self Row */}
                        {reportType !== 'Team' && <ChartRowDF
                          headingContent='Self' 
                          colour='blue'
                          average={+questionAverageSelfCurrent}
                          responses={questionResponsesSelfCurrent}
                          bold={true}
                          selfRow={true}
                          reportType={reportType}
                        />}

                        {/* Previous Self Row */}
                        {reportType === 'Comparison' && (
                          <ChartRowDF
                            colour='#0051ff'
                            average={+questionAverageSelfPrevious[questionID]}
                            responses={questionResponsesSelfPrevious[questionID]}
                            opacity='0.4'
                            selfRow={true}
                            bold={true}
                          />
                        )}



                        {/* All Reviewers Row */}
                        {reportType === 'Group' && (
                          <ChartRowDF
                            headingContent='All Reviewers' 
                            colour='gray'
                            average={+questionCombinedReviewersAverage[questionID]}
                            responses={questionCombinedReviewersResponses[questionID]}
                          />
                        )}


                        {/* Reviewer Relationship Rows */}
                        {reportType !== 'Self' && relationshipKeys.map((relationshipID, relationshipIndex) => {
                          const relationshipName = current360Relationships[relationshipID]['name']
                          const relationshipColour = current360Relationships[relationshipID]['colour']
                          let currentAverage = 0
                          if (reportType === 'Team') {
                            currentAverage = questionAveragesByRelationshipCurrent[relationshipID][questionID]
                          } else {
                            currentAverage = average(questionAveragesByRelationshipCurrent[relationshipID][questionID])
                          }
                          let responsesByRelationshipCurrent = [0,0,0,0,0,0]
                          if (reportType === 'Group') {
                            responsesByRelationshipCurrent = questionResponsesByRelationshipCurrent[relationshipID][questionID]
                          } else if (reportType === 'Team') {
                            responsesByRelationshipCurrent = questionResponsesByRelationshipCurrent[questionID]
                          } else {
                            const responseValues = questionResponsesByRelationshipCurrent[questionID][relationshipID]
                            responseValues.forEach(value => {
                              responsesByRelationshipCurrent[value]++
                            })
                          }
                          let previousAverage
                          let previousResponses
                          if (reportType === 'Comparison') {
                            previousAverage = questionAveragesByRelationshipPrevious[relationshipID][questionID]
                            previousResponses = questionResponsesByRelationshipPrevious[relationshipID][sectionID][questionID]
                          }
                          
                          return (
                            <div key={relationshipID} className='db w-100'>
                              {/* Current Reviewer Row */}
                              <ChartRowDF
                                headingContent={relationshipName} 
                                colour={relationshipColour}
                                average={currentAverage ? +currentAverage : 0}
                                responses={responsesByRelationshipCurrent}
                                reportType={reportType}
                              />

                              {/* Previous Reviewer Row */}
                              {reportType === 'Comparison' && (
                                <ChartRowDF
                                  colour={relationshipColour}
                                  average={previousAverage ? +previousAverage : 0}
                                  responses={previousResponses}
                                  opacity='0.4'
                                />
                              )}
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        )
      })
    ) : (
      <LoadingState />
    )
  }
}

export default DetailedFeedbackChart;
