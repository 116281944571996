import React, { Component } from 'react';
import reactCSS from 'reactcss';
import { db } from '../firebase';
import { ChromePicker } from 'react-color';
import Modal from './global/modal';
import { UpArrow, DownArrow, legacyUpArrow } from './../svg/svgs';

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

class RelationshipForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      saving: false,
      firstInstance: true,
      displayPrimaryColourPicker: false,
      relationshipcolour: this.props.relationship.colour,
      showDeleteModal: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.pressUp = this.pressUp.bind(this);
    this.pressDown = this.pressDown.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();

    let relationshipData = {};
    relationshipData = {
      name: this.relationshipName.value,
      colour: this.relationshipColour.value,
      description: this.relationshipDescription.value,
    };
    this.setState({
      saving: true,
    });
    if (this.relationshipKey.value == this.props.relationshipKey) {
      // Update relationship data
      db.doUpdateRelationship(this.props.accountId, this.props.current360Id, this.props.relationshipKey, relationshipData, this.props.relationshipNum - 1).then(
        (data) => {
          // Update relationship props
          if (this.props.relationship) {
            this.props.relationship.name = relationshipData.name;
            this.props.relationship.colour = relationshipData.colour;
            this.props.relationship.description = relationshipData.description;
          }

          setTimeout(
            function() {
              this.setState({
                saving: false,
                firstInstance: false,
              });
            }.bind(this),
            500
          );
        }
      );
    } else {
      // Remove and recreate relationship (as the Key has changed)
      db.doDeleteRelationship(this.props.accountId, this.props.current360Id, this.props.relationshipKey).then((data) => {
        db.doCreateNewRelationship(
          this.props.accountId,
          this.props.current360Id,
          this.relationshipKey.value,
          relationshipData,
          this.props.relationshipNum - 1
        ).then((data) => {
          let relationships = this.props.relationships;
          delete relationships[this.props.relationshipKey];
          relationships[this.relationshipKey.value] = { sortOrder: this.props.relationshipNum - 1, ...relationshipData };
          setTimeout(
            function() {
              this.setState({
                saving: false,
                firstInstance: false,
              });
              this.props.updateAfterReplace(relationships);
            }.bind(this),
            500
          );
        });
      });
    }
  }

  handleChange(e) {
    e.preventDefault();
  }

  pressUp(e) {
    e.preventDefault();
    this.props.moveRelationship(this.props.accountId, this.props.current360Id, this.props.relationshipNum - 2, this.props.relationshipNum - 1);
  }

  pressDown(e) {
    e.preventDefault();
    this.props.moveRelationship(this.props.accountId, this.props.current360Id, this.props.relationshipNum - 1, this.props.relationshipNum);
  }

  render() {
    const pickerStyles = reactCSS({
      default: {
        color: {
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          // background: `rgba(${ this.state.color.r }, ${ this.state.color.g }, ${ this.state.color.b }, ${ this.state.color.a })`,
        },
        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
        primarycolorinput: {
          borderColor: this.state.relationshipcolour,
          borderWidth: '2px',
        },
      },
    });

    let relationshipName;
    let relationshipDescription;
    if (this.props.relationship) {
      relationshipName = this.props.relationship.name;
      relationshipDescription = this.props.relationship.description;
    }
    let relationshipsList;
    if (this.props.relationshipsList) {
      relationshipsList = this.props.relationshipsList;
    }

    return (
      <form action='/' className='pv3' onSubmit={this.handleSubmit}>
        <p>
          <strong>Relationship #{this.props.relationshipNum}</strong> {this.props.relationshipNum === 1 ? 'This will appear first in the report charts.' : ''}
        </p>
        <div className='flex w-70 justify-start'>
          <div className='w-10 mr3'>
            <label className='db'>Key</label>
            <input
              maxLength='3'
              required
              ref={(input) => (this.relationshipKey = input)}
              type='text'
              className='w-100'
              defaultValue={this.props.relationshipKey}
            />
          </div>
          <div className='w-20'>
            <label className='db'>Colour</label>
            <input
              required
              style={pickerStyles.primarycolorinput}
              ref={(input) => (this.relationshipColour = input)}
              type='text'
              className='w-100'
              onClick={(e) => {
                this.setState({ displayPrimaryColourPicker: true });
              }}
              defaultValue={this.state.relationshipcolour}
            />
            {this.state.displayPrimaryColourPicker ? (
              <div style={pickerStyles.popover}>
                <div
                  style={pickerStyles.cover}
                  onClick={(e) => {
                    this.setState({ displayPrimaryColourPicker: false });
                  }}
                />
                <ChromePicker color={this.state.relationshipcolour} onChange={(color) => this.setState(byPropKey('relationshipcolour', color.hex))} />
              </div>
            ) : null}
          </div>
        </div>
        <div className=''>
          <div className='w-60'>
            <label className='db'>Name</label>
            <input required ref={(input) => (this.relationshipName = input)} type='text' className='w-100' defaultValue={relationshipName} />
          </div>
          <div className='w-80'>
            <label className='db'>Description</label>
            <textarea ref={(input) => (this.relationshipDescription = input)} className='w-100' defaultValue={relationshipDescription} />
          </div>
        </div>

        <div className='flex justify-between'>
          <div className='flex justify-between'>
            <button type='submit' disabled={this.props.current360StatusCode === 1 ? false : true} className={this.state.saving ? 'btn btn--disabled' : 'btn'}>
              Save Relationship
            </button>
            {Object.keys(this.props.relationships).length <= 2 ? (
              <button type='button' className='btn btn--red ml3 disabled' disabled>
                Minimum Relationships Reached
              </button>
            ) : (
              <button
                type='button'
                className='btn btn--red ml3'
                onClick={(e) => {
                  this.setState({ showDeleteModal: true });
                }}
              >
                Delete this relationship
              </button>
            )}
          </div>
          <div className='flex flex-wrap'>
            {this.props.relationshipNum > 1 ? (
              <button className='mh3 has-tooltip w1' data-tooltip='Move relationship Up' onClick={(e) => this.pressUp(e)}>
                <UpArrow width='40' height='40' fill='#3b4e7e' title='Move relationship Up' />
              </button>
            ) : (
              <></>
            )}
            {this.props.relationshipNum < relationshipsList.length ? (
              <button className='mh3 has-tooltip w1' data-tooltip='Move relationship Down' onClick={(e) => this.pressDown(e)}>
                <DownArrow width='40' height='40' fill='#3b4e7e' title='Move relationship Down' />
              </button>
            ) : (
              <></>
            )}
          </div>
        </div>
        <span className={this.state.saving ? 'loading-spinner' : !this.state.firstInstance ? 'loading-done' : ''} />
        <hr className='mt4' />
        <Modal
          isOpen={this.state.showDeleteModal}
          contentLabel='Delete this relationship'
          onClose={() => this.setState({ showDeleteModal: false })}
          content={
            <div className='tc'>
              <h2>Are you sure you wish to delete this relationship?</h2>

              <div className=''>
                <button
                  className='btn mb1'
                  onClick={(e) => {
                    this.props.deleteCurrent(this.props.accountId, this.props.current360Id, this.props.relationshipKey, this.props.relationshipNum - 1);
                  }}
                >
                  Yes, delete this relationship
                </button>
                <br />
                <button
                  className='btn btn--red mt1'
                  onClick={(e) => {
                    this.setState({ showDeleteModal: false });
                  }}
                >
                  No, keep this relationship
                </button>
              </div>
            </div>
          }
        />
      </form>
    );
  }
}

//
export default RelationshipForm;
