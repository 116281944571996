import React, { Component } from 'react';

export default class PageHeader extends Component {
  constructor(props) {
    super(props);

    this.state = { ...this.props.state };
  }
  render() {
    const {h2Class, h2Text, strong, h3Text, line} = this.props
    return (
      <div>
        <h2 className={`f2 mv0 ${h2Class}`}>
          {h2Text ? h2Text : ''}
          {strong && <strong>{strong}</strong>}
        </h2>
        {h3Text && <h3 className='f5 gray'>{h3Text}</h3>}
        {line && <hr className='' />}
      </div>
    );
  }
}
