import React, { Component } from 'react';
import {valueCheck, percentageCheck} from '../../helpers'

export default class RowAveragesColumnDF extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {average, valueColour, barColour, opacity, allReviewers, distribution, distributionArray, colours, selfRow, reportType} = this.props
      return (
      <div className={`w-${reportType === 'Self' || reportType === 'Team' ? '7' : '5'}0 mv1`}>
        <div className={`w-100 ${distribution ? 'h-100' : ''}`}>
          <p className={`flex ma0 ${distribution ? 'h-100' : ''}`}>
            <strong className='w-10' style={{ color: selfRow || reportType === 'Team' ? '' : valueColour }}>{!distribution ? valueCheck(average.toFixed(1)) : ''}</strong>
            <span className={`w-100 bg-light-gray ${distribution ? 'flex' : ''}`}>
              {
                distribution ? 
                  distributionArray.map((portion, portionIndex) => 
                  {
                    return (
                      <span
                        key={portionIndex} 
                        className='h-100 db' 
                        style={{
                          width: `${portion.toFixed(1)}%`, 
                          backgroundColor: `${colours[portionIndex]}` 
                        }} 
                      />
                    )
                  })
                :
                  (
                    <span
                      className={`bg-${allReviewers ? 'gray' : 'blue'} h-100 db`}
                      style={{
                        width: `${(percentageCheck(average.toFixed(1)) / 5) * 100}%`,
                        backgroundColor: barColour ? barColour : '',
                        opacity: opacity ? opacity : ''
                      }} 
                    />
                  )
              }
            </span>
          </p>
        </div>
      </div>
    )
  }
}
